import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';  
import { shopReducer } from './shop.slice';  
import { commonReducer } from './common.slice';  
import { customerReducer } from './customer.slice';  

export * from './auth.slice';
export * from './users.slice';  
export * from './shop.slice';  
export * from './common.slice'; 
export * from './customer.slice';  

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        shop: shopReducer,
        common:commonReducer,
        customer:customerReducer
    },
});