import * as React from 'react'; 
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import AllOrder from './AllOrders'; 
import NewOrder from './NewOrders'; 
import Box from '@mui/material/Box';  
import { BrowserRouter as Router,  useParams } from 'react-router-dom'; 
export { Order };
 
  function Order(props) {    
   const { type } = useParams(); 
  const { user: authUser } = useSelector((x) => x.auth); 
  if (!authUser) { 
    history.navigate('/signin');
  } 
  return ( 
    <>
        {(typeof type =="undefined" || type =="" ) ? <AllOrder /> : <NewOrder orderType={type} />  } 
    </> 
 )
}