import { useEffect, useState, useContext } from "react"; 
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  useStripe,
  CardElement,
  useElements,
} from '@stripe/react-stripe-js';
import CartContext from '../CartManagement/CartContext';


const PaymentData = ({placeOrder}) => {
  const { cart, addToCart, removeFromCart, totalAmount, itemCount } = useContext(CartContext);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardElement); // Replace CardElement with the appropriate element from Stripe Elements
    console.log(cardElement, "cardElementcardElementcardElementcardElement")
    const { error: submitError } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/customers/create_payment_indent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ amount: totalAmount }),
    });

    const { client_secret } = await res.json();
    // const { client_secret } = await paymentIndent();

    const { error } = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: cardElement,
      },
    });

    if (error) {
      console.log(error, "errrrrrrrrrrrrrrrrrrrrrr")
      setErrorMessage(error.message);
    } else {
      // Payment succeeded
      placeOrder();
      console.log("sssssssssssssssssssssssssssssssss")
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {/* Rest of your form */}
      <button className="gradients-bg btn btn-h-40 w-100 mt-30" type="submit">Make Payment</button>
    </form>

  );
};

const stripePromise = loadStripe('pk_test_v5Ly2LZpUPbaYa44rfZSgsBv');

const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const CheckoutForm = ({placeOrder}) => (
  <Elements stripe={stripePromise} options={options}>
    <PaymentData placeOrder={placeOrder} />
  </Elements>
);


export default CheckoutForm;