import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux"; 
import { history } from "_helpers";
import {   store } from '_store';

export { CustomerRoute };

function CustomerRoute({ children }) {
  // const { user: authUser } = useSelector((x) => {
    // console.log(x, "hereeeeeeeeeeee")

    // x.customers});
      const user = localStorage.getItem('cust');

  if (!user) { 
    return <Navigate to="/home" state={{ from: history.location }} />;
  }
 
  return children;
}
 
