import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { commonActions, authActions, store } from '_store';
import { history, fetchWrapper } from '_helpers';
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import googelicon from "images/google-icon.png";
import facebookicon from "images/facebook-icon.png";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import { Checkbox, FormControl } from "@mui/material";
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CartContext from '../CartManagement/CartContext';
import CheckoutForm from './CheckoutForm'; // Make sure to provide the correct path to the PaymentForm component

import dayjs from 'dayjs';
//import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Calendar from 'react-calendar';
// import { FaCalendarAlt } from 'react-icons/fa'; // Import the calendar icon from react-icons




import "./checkout.scss";

export { CheckOut };

function CheckOut() {

  const [date, setDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);

  const onChange = (selectedDate) => {
    setDate(selectedDate);
    setShowCalendar(false); // Close the calendar after selecting a date
  };


  const { cart, addToCart, removeFromCart, totalAmount, itemCount } = useContext(CartContext);
  // const [value, setValue] = React.useState(dayjs('2022-04-17'));
  const dispatch = useDispatch();
  const { registerCustomer: RegisterCustomerResponse } = useSelector((x) => x.common);
  const { placeOrder: placeOrderResponse } = useSelector((x) => x.common);
  const [customerLoggedIn, setCustomerLoggedIn] = React.useState(false);
  const [customerResponse, setResigterCustomerResp] = React.useState([]);
  useEffect(() => {
    if (localStorage.getItem("cust")) {
      const customer = JSON.parse(localStorage.getItem("cust"));
      if (customer) {
        setCustomerLoggedIn(true)

      }

    }
  }, []);


  useEffect(() => {
    setResigterCustomerResp(RegisterCustomerResponse)
    if (RegisterCustomerResponse && RegisterCustomerResponse.data && Object.keys(RegisterCustomerResponse.data).length > 0) {
      localStorage.setItem("cust", JSON.stringify(RegisterCustomerResponse.data));
      handleCloseSignIn(true)
      setCustomerLoggedIn(true)
    }
  }, [RegisterCustomerResponse]);

  const [orderPlaceResponse, setOrderPlaceResponse] = React.useState([]);

  useEffect(() => {
    if (placeOrderResponse && placeOrderResponse.data && !placeOrderResponse.data.message && Object.keys(placeOrderResponse.data).length > 0) {
      removeFromCart();
      history.navigate("/thanks");
    }
    else {
      setOrderPlaceResponse(placeOrderResponse)
    }
  }, [placeOrderResponse]);
  console.log(store.getState())

  const [valueAM, setValueAM] = React.useState();
  const handleChangeAM = (newValueAM) => {
    setValueAM(newValueAM);
  };

  const [valuePM, setValuePM] = React.useState();
  const handleChangePM = (newValuePM) => {
    setValuePM(newValuePM);
  };

  const [openSignIn, setOpenSignIn] = React.useState(false);
  const handleOpenSignIn = () => setOpenSignIn(true);
  const handleCloseSignIn = () => setOpenSignIn(false);
  const [formFields, setFormFields] = useState({
    firstName: '',
    emailAddress: '',
    phoneNumber: '',
    errors: {
      firstName: '',
      emailAddress: '',
      phoneNumber: '',
    }
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormFields((prevFormFields) => ({
      ...prevFormFields,
      [name]: value,
    }));
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};

    if (!formFields.firstName) {
      errors.firstName = 'Name is required';
    }

    if (!formFields.emailAddress) {
      errors.emailAddress = 'Email is required';
    } else if (!isValidEmail(formFields.emailAddress)) {
      errors.emailAddress = 'Invalid email format';
    }

    if (!formFields.phoneNumber) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!isPhoneNumber(formFields.phoneNumber)) {
      errors.phoneNumber = 'Invalid Phone Number';
    }


    setFormFields((prevData) => ({
      ...prevData,
      errors: { ...errors },
    }));

    if (Object.keys(errors).length === 0) {
      console.log(formFields)
      dispatch(commonActions.registerCustomer(formFields));

    }
  };
  const [orderError, setOrderError] = React.useState("");

  const placeOrder = () => {
    let shopId = "";
    const customerId = JSON.parse(localStorage.getItem("cust")).id;
    if (cart && Object.keys(cart).length > 0) {
      shopId = cart[0].shopId;
    }
    const cartItems = cart;
    console.log(customerId, shopId, cart, "----------------------")
    if (customerId && shopId && Object.keys(cart).length > 0) {
      const orderParams = { shopId: shopId, customerId: customerId, cartItems: cartItems, time: valuePM }
      console.log(orderParams)
      dispatch(commonActions.placeOrder(orderParams));

      setOrderError("")
    }
    else {
      setOrderError("Something went wrong, please check your cart")
    }
  }


  return (
    <Box className="lightG-bg">



      <Box className="checkOutbg py-60">
        <Container fixed className="container">
          <Grid container className="" spacing={4}>
            <Grid item xs={12} sm={6} lg={8} className="checkOut-left">
              {customerLoggedIn == false &&
                <Box className="checkOutBlockouter active">
                  <span className="roundeBox"></span>
                  <Box className="white-bg p-20 border-radius">
                    <h2 className="font-medium">
                      Sign in to place your order
                    </h2>
                    <p className="pt-5">Sign in to proceed</p>
                    <Box className="mt-15">
                      <Button
                        onClick={handleOpenSignIn}
                        className="button-radius gradients-bg btn"
                        variant="contained"
                      >
                        Sign in
                      </Button>
                    </Box>
                  </Box>
                </Box>
              }

              {customerLoggedIn && (
                <Box className="checkOutBlockouter active checked">
                  <span className="roundeBox"></span>
                  <Box className="white-bg p-20 border-radius">
                    <h2 className="font-medium">
                      Logged in
                    </h2>
                    <p className="pt-5">You have successfully signed in</p>

                  </Box>
                </Box>
              )}

              <Box className={`checkOutBlockouter ${customerLoggedIn ? 'active' : ''} pt-30`}>

                <span className="roundeBox"></span>
                <Box className="white-bg p-20 border-radius">
                  <h2 className="mainHeading font-subheading">Pick Up</h2>
                  <p className="pt-5">Select a delivery receive time</p>
                  <p className="pt-5 pb-20 d-flex storeTime flex-wrap">
                    <dd className="d-flex mobileTime"><AccessTimeOutlinedIcon className="timeIcon" />
                      <span>Store Timing <b> From:</b> 09:00 am <b> To:</b> 08:00 pm</span></dd>
                    <span className="ml-auto d-flex ml-xs-0 pt-xs-10 pColor">
                      <StorefrontOutlinedIcon />
                      <b>Store Closed</b> on Sunday
                    </span>
                  </p>



                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label="Basic date picker" />
      </DemoContainer>
    </LocalizationProvider>  */}
                  <Grid
                    container
                    spacing={[3, 3]}
                    columnSpacing={{ xs: 0, sm: 2, md: 3 }}
                    className="dateTimeer"
                  >
                    <Grid item xs={12} sm={6}>
                      <label className="formLabel">Select Time</label>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <div style={{ position: 'relative' }}>
                            <input
                              type="text"
                              value={date.toLocaleDateString()}
                              onFocus={() => setShowCalendar(true)} // Open calendar when input is focused
                              readOnly // Make the input read-only to prevent manual input
                            />
                            <div
                              style={{
                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }}
                              onClick={() => setShowCalendar(true)} // Open calendar when icon is clicked
                            >cal</div>
                            {showCalendar && (
                              <div style={{ position: 'absolute', zIndex: 999 }}>
                                <Calendar
                                  onChange={onChange}
                                  value={date}
                                  minDate={new Date()} // Set minimum date to today
                                // You can add more props to customize the calendar
                                />
                              </div>
                            )}
                          </div>
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <label className="formLabel">Select Time</label>
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker className='form-control' value={valuePM} onChange={handleChangePM}
                            renderInput={(params) => <TextField {...params} />} />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>


                  </Grid>
                  <Box className="dateTimeerShow mt-20">
                    <Grid
                      container
                      spacing={[0, 0]}
                      columnSpacing={{ xs: 0, sm: 2, md: 3 }}
                    >
                      <Grid item xs={12} sm={6}>
                        <span className="d-flex align-items-flex-start">
                          <AccessTimeOutlinedIcon className="timeIcon" />
                          <p className="d-flex flex-wrap pl-10">
                            <b className="w-100">11:30 am</b>
                            Saturday 28-05-2023</p>
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={6} className="d-flex align-items-flex-start pt-xs-10">
                        <Button className="btn changedateTime ml-auto gColor ml-xs-0" variant="text"><ModeEditOutlineOutlinedIcon /> Change</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <Box className="checkOutBlockouter  pt-30">
                <span className="roundeBox"></span>
                <Box className="white-bg p-20 border-radius">
                  <h2 className="mainHeading font-subheading">Payment method</h2>
                  {!customerLoggedIn &&
                    <p className="pt-5"> Please login to place an order.</p>
                  }
                  {customerLoggedIn &&
                    // <Button
                    //   onClick={() => placeOrder()}
                    //   className="button-radius primary-btn btn w-40"
                    //   variant="contained"
                    // >
                    //   Place Order
                    // </Button>

                    <Box className="pt-20">
                      <Grid container columnSpacing={0} >
                        <Grid item xs={12} sm={4}>
                          <span className="creditDebitTxt"><CreditCardOutlinedIcon /> Credit and Debit cards</span>
                        </Grid>



                        <Grid className="ml-auto ccpayInfo" item xs={12} sm={7}>
                          <Box className="pt-5">
                            <CheckoutForm placeOrder={placeOrder} />
                          </Box>
                          {orderError && orderError != "" &&
                            <Box className="errorMessage pt-20 pb-5">{orderError}</Box>
                          }

                          {orderPlaceResponse && orderPlaceResponse.error && orderPlaceResponse.error.message &&
                            <Box className="errorMessage pt-20 pb-5">{orderPlaceResponse.error.message}</Box>
                          }
                          {orderPlaceResponse && orderPlaceResponse.data && orderPlaceResponse.data.message &&
                            <Box className="errorMessage pt-20 pb-5">{orderPlaceResponse.data.message}</Box>
                          }

                        </Grid>
                      </Grid>
                    </Box>
                  }


                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4} className="checkOut-right">
              <Box className="addTocartBar white-bg border-radius">
                <Box className="CartHeader d-flex">
                  <h3 className="gColor font-extralarge">Your Cart</h3>
                  <span className="ml-auto cartItems fw-500 bColor">{itemCount} items</span>
                </Box>
                <List className="addCartProductList">
                  {cart && Object.keys(cart).length > 0 &&
                    cart.map(function (cartItem, i) {
                      return <ListItem className="flex-wrap">
                        <Grid container spacing={[0, 0]} columnSpacing={{ xs: 0, sm: 3, md: 3 }}>
                          <Grid item xs={5} sm={3} lg={3}>
                            <Box className="cartImage">
                              <img src={cartItem.productImage} />
                            </Box>
                          </Grid>
                          <Grid item xs={5} sm={9} lg={9}>


                            <Box className="w-100">
                              <h2 className="font-normal fw-500 gColor">
                                {cartItem.productTitle}
                              </h2>

                            </Box>
                            <Box className="qutPrice w-100 px-0 checkOutPriceInfo">
                              {/*     <Box className="qutBox">
                            <button>
                              <RemoveOutlinedIcon />
                            </button>
                            <TextField
                              type="number"
                              value="1"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <button>
                              <AddOutlinedIcon />
                            </button>
                          </Box> */}
                              <span className="font-normal w-100 d-flex align-items ">
                                <p className="font-xxs">{cartItem.productQuantity}</p>
                                <p className="font-xxs">QTY: 1</p>
                                <b className="m-auto">$ {cartItem.productPrice}</b>
                              </span>
                            </Box>
                          </Grid>
                        </Grid>
                      </ListItem>
                    })}
                </List>
              </Box>

              <Box className="ml-auto white-bg invoiceInfo border-radius mt-30">
                <h2 className="gColor font-extralarge">Invoice</h2>
                <List>
                  <ListItem className="d-flex">
                    <span>Item total</span>
                    <span className="ml-auto amount">$ {totalAmount}</span>
                  </ListItem>
                  <ListItem className="d-flex">
                    <span>Tax</span>
                    <span className="ml-auto amount">$0</span>
                  </ListItem>
                  <ListItem className="d-flex">
                    <span>To pay</span>
                    <span className="ml-auto amount">$ {totalAmount}</span>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        className="modalPopupTxt"
        open={openSignIn}
        onClose={handleCloseSignIn}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Button className="closepopup" onClick={handleCloseSignIn}>
              <CloseIcon />
            </Button>

            <Box className="login-wrapper checkoutSingup">
              <Box className="login-bg">
                <h2 className="font-subheading pColor">Sign In</h2>
                <form onSubmit={handleSubmit} className="pt-10">
                  <FormControl fullWidth className="mb-20">
                    <label>Name</label>
                    <TextField
                      className="form-control"
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="Normal"
                      name="firstName"
                      value={formFields.firstName}
                      onChange={handleInputChange}
                    />
                    {formFields.errors.firstName && <span className="error">{formFields.errors.firstName}</span>}
                  </FormControl>
                  <FormControl fullWidth className="mb-20">
                    <label>Email</label>
                    <TextField
                      className="form-control"
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="Email"
                      name="emailAddress"
                      value={formFields.emailAddress}
                      onChange={handleInputChange}
                    />
                    {formFields.errors.emailAddress && <span className="error">{formFields.errors.emailAddress}</span>}

                  </FormControl>
                  <FormControl fullWidth className="mb-20">
                    <label>Mobile number</label>
                    <TextField
                      className="form-control"
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="Mobile number"
                      name="phoneNumber"
                      value={formFields.phoneNumber}
                      onChange={handleInputChange}
                    />
                    {formFields.errors.phoneNumber && <span className="error">{formFields.errors.phoneNumber}</span>}
                  </FormControl>
                  {customerResponse && customerResponse.data && customerResponse.data.message &&
                    <Box className="errorMessage">{customerResponse.data.message}</Box>
                  }
                  {customerResponse && customerResponse.error && customerResponse.error.message &&
                    <Box className="errorMessage">{customerResponse.error.message}</Box>
                  }
                  <button type="submit" className="button-radius btn gradients-bg border-none w-100">Get Started</button>
                </form>
                <Box className="text-line w-80 text-center mx-auto my-30">
                  <span>or login with</span>
                </Box>
                <Box className="btn-row d-flex">
                  <Button
                    className="btn w-100 white-btn social-login-btn"
                    variant="outlined"
                  >
                    <img src={googelicon} className="mr-10" />
                    Google
                  </Button>

                </Box>
                <Box className="d-flex pt-20 form-group checkbox-txt mt-20">
                  <Checkbox />
                  <p>
                    By continuing, you agree to our <b>Term & Conditions</b> and{" "}
                    <b>Privacy Policy</b>.
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
