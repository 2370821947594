import * as React from "react";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';

import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Sidebar } from "ShopDashboard/sidebar/Sidebar";
import "./addproduct.scss";
import { Button, Checkbox, Drawer, FormControl, FormControlLabel, MenuItem, Select, TextField } from "@mui/material";
import { NavLink, Navigate } from "react-router-dom";
import { Footer } from "footer/Footer";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import demoImg from "images/demo-img.png";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import Strawberry from 'images/strawberry.png';

import Switch from '@mui/material/Switch';


const label = { inputProps: { "aria-label": "Checkbox demo" } };



export { AddProduct };

function AddProduct() {

    const [thisweek, setthisweek] = React.useState('');
    const handleChange = (event) => {
        setthisweek(event.target.value);
    };


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <Box className="addProductBar" sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 450 }}>
            <Box className="AddProductHeader d-flex">
                <h3 className="font-subheading pColor">Add Product</h3>
                <Button className="closeBtn ml-auto" onClick={toggleDrawer(anchor, false)}>
                    <CloseOutlinedIcon />
                </Button>
            </Box>

            <Box className="productFormOuter p-30">
                <Box className="addProductImg pb-20">
                    <Button component="label" className='browseBtnOuter'>
                        <img src={demoImg} />
                        <span className='browseTxt w-100 pt-15 font-small'>Drop your image here, or browse <span className='font-xxs fw-400 w-100 d-block pt-5'>Support: JPG, JPEG, PNG</span></span>
                        <small className='photoSizetxt w-100 pt-20 pColor'>Browse</small>
                        <input type="file" hidden /></Button>
                </Box>

                <Box className="productField pb-20">
                    <FormControl fullWidth>
                        <label>Product Name</label>
                        <TextField className='form-control' hiddenLabel id="filled-hidden-label-normal" placeholder="Type Name" />
                    </FormControl></Box>


                <Box className="productField pb-20">
                    <FormControl fullWidth>
                        <label>Select Unit</label>
                        <Select className='form-control' value={thisweek} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without abel' }} >
                            <MenuItem value="">Select</MenuItem>
                            <MenuItem value={1}>Product 01</MenuItem>
                            <MenuItem value={2}>Product 02</MenuItem>
                            <MenuItem value={2}>Product 03</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box className="productField pb-20">
                    <FormControl fullWidth>
                        <label>Price</label>
                        <TextField className='form-control' hiddenLabel id="filled-hidden-label-normal" placeholder="$" />
                    </FormControl></Box>

                <Box>
                    <FormControl fullWidth>
                        <Button className='btn gradients-bg btn-h-40 w-100' variant='contained'>Add Product</Button>
                    </FormControl></Box>
            </Box>
        </Box>
    );

    const [value, setValue] = React.useState('1');

    const handleChangeTAB = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <Sidebar />
            <Box className="dashboardRightcontent">
                <Box className="filter-bg border-bottom">
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} lg={3}>
                            <span className="search-bg">
                                <IconButton type="button" sx={{ p: "0px" }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Enter the product name..."
                                    inputProps={{ "aria-label": "Enter the product name..." }}
                                />
                            </span>
                        </Grid>

                        <Grid item xs={12} sm={6} lg={3} className="text-right d-flex ml-auto">


                            {["right"].map((anchor) => (
                                <React.Fragment key={anchor}>
                                    <Button className="btn outline-btn ml-auto" onClick={toggleDrawer(anchor, true)}><AddOutlinedIcon className="mr-5" /> Add Product</Button>


                                    <Drawer className="addProductDrawer" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} >
                                        {list(anchor)}
                                    </Drawer>
                                </React.Fragment>
                            ))}

                        </Grid>

                    </Grid>
                </Box>

                <Box className="addProductlayout pt-20">

                    <Box>
                        <TabContext value={value}>
                            <Box>
                                <TabList onChange={handleChangeTAB} aria-label="lab API tabs example" className="productTabmenu">
                                    <Tab label="All Products" value="1" />
                                    <Tab label="Manual added Products" value="2" />
                                    <Tab label="Default Products" value="3" />
                                </TabList>
                            </Box>

                            <TabPanel value="1" className="p-0 mt-20 mt-xs-0 addProductOuter">
                                <Grid container className="addProductlistHeader hiddenXS">
                                    <Grid item xs={12} sm={6} lg={6} className="catCol">
                                        <span>Items</span>
                                    </Grid>
                                    <Grid item xs={12} sm={2} lg={2} className="addProductCol text-center">
                                        <span>Amount</span>
                                    </Grid>
                                    <Grid item xs={12} sm={2} lg={2} className="addProductCol text-center">
                                        <span>Stock</span>
                                    </Grid>
                                    <Grid item xs={12} sm={2} lg={2} className="addProductCol text-center">
                                        <span>Action</span>
                                    </Grid>
                                </Grid>


                                <Grid container className="addProductRow">

                                    <Grid item xs={12} sm={6} lg={6} className="addProductCol">
                                        <Box className="productimgTxt">
                                            <Box className="productimg"><img src={Strawberry} /></Box>
                                            <Box className="productinfo">
                                                <h3>Strawberry</h3>
                                                <span className='popupTxt'>
                                                    1 box (180 gms - 200 gms)
                                                </span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Price</span>
                                        <span className='priceBtn'>
                                            <span className='popupTxt addPricetxt'>Add Price</span>
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Stock</span>
                                        <span className="checkBtn">
                                            <Switch {...label} defaultChecked />
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className='editBtn'>
                                            <ModeEditOutlineOutlinedIcon /> Edit
                                        </span>
                                    </Grid>

                                </Grid>

                                <Grid container className="addProductRow">

                                    <Grid item xs={12} sm={6} lg={6} className="addProductCol">
                                        <Box className="productimgTxt">
                                            <Box className="productimg"><img src={Strawberry} /></Box>
                                            <Box className="productinfo">
                                                <h3>Strawberry</h3>
                                                <span className='popupTxt'>
                                                    1 box (180 gms - 200 gms)
                                                </span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Price</span>
                                        <span className='priceBtn'>
                                            <span className='popupTxt addPricetxt'>Add Price</span>
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Stock</span>
                                        <span className="checkBtn">
                                            <Switch {...label} defaultChecked />
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className='editBtn'>
                                            <ModeEditOutlineOutlinedIcon /> Edit
                                        </span>
                                    </Grid>

                                </Grid>


                                <Grid container className="addProductRow">

                                    <Grid item xs={12} sm={6} lg={6} className="addProductCol">
                                        <Box className="productimgTxt">
                                            <Box className="productimg"><img src={Strawberry} /></Box>
                                            <Box className="productinfo">
                                                <h3>Strawberry</h3>
                                                <span className='popupTxt'>
                                                    1 box (180 gms - 200 gms)
                                                </span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Price</span>
                                        <span className='priceBtn'>
                                            <span className='popupTxt addPricetxt'>Add Price</span>
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Stock</span>
                                        <span className="checkBtn">
                                            <Switch {...label} defaultChecked />
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className='editBtn'>
                                            <ModeEditOutlineOutlinedIcon /> Edit
                                        </span>
                                    </Grid>

                                </Grid>

                                <Grid container className="addProductRow">

                                    <Grid item xs={12} sm={6} lg={6} className="addProductCol">
                                        <Box className="productimgTxt">
                                            <Box className="productimg"><img src={Strawberry} /></Box>
                                            <Box className="productinfo">
                                                <h3>Strawberry</h3>
                                                <span className='popupTxt'>
                                                    1 box (180 gms - 200 gms)
                                                </span>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Price</span>
                                        <span className='priceBtn'>
                                            <span className='popupTxt addPricetxt'>Add Price</span>
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className="hiddenSM iPhoneTxt">Stock</span>
                                        <span className="checkBtn">
                                            <Switch {...label} defaultChecked />
                                        </span>
                                    </Grid>
                                    <Grid item xs={4} sm={2} lg={2} className="addProductCol text-center">
                                        <span className='editBtn'>
                                            <ModeEditOutlineOutlinedIcon /> Edit
                                        </span>
                                    </Grid>

                                </Grid>


                            </TabPanel>

                            <TabPanel value="2">Item Two</TabPanel>
                            <TabPanel value="3">Item Three</TabPanel>
                        </TabContext>
                    </Box>

                </Box>


                <Footer />
            </Box>
        </Box>
    );
}
