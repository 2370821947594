import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Footer } from "footer/Footer";
import { Container } from "@mui/system";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";

import { Button, List, ListItem } from "@mui/material";
import { NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import SlideToggle from "react-slide-toggle";
import MenuIcon from "@mui/icons-material/Menu";


import "./myaccount.scss";

export { MyAccount };

function MyAccount() {
  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);
  const [toggleEvent, setToggleEvent] = React.useState(0);

  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };

const myAccountOrderMenu = (
    <List>
      <ListItem>
        <NavLink to="/orderlist">Orders List</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/account-settings">Account Settings</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/support">Support</NavLink>
      </ListItem>
    </List>
  )

  return (
    <Box className="blue-bg">
      <Container fixed className="py-60">
        <Box className="white-bg border-radius myaccountInfo">
          <Grid container className="d-flex profileBtnRow">
            <Grid item xs={12} sm={8}>
              <h2 className="font-subheading">Hi, Robert H. Beadle</h2>
              <p className="font-small d-flex flex-wrap">
                <span className="pr-25 d-flex pt-5">
                  <LocalPhoneOutlinedIcon className="mr-5" /> 510-554-4468
                </span>{" "}
                <span className="d-flex pt-5">
                  <LocalPostOfficeOutlinedIcon className="mr-5" />{" "}
                  <a href="mailto:RobertHBeadle@jourrapide.com">
                    RobertHBeadle@jourrapide.com
                  </a>
                </span>
              </p>
            </Grid>
            <Grid item xs={12} sm={4} className="d-flex pt-xs-20">
              <Button
                className="btn border-btn button-radius white-btn ml-auto ml-xs-0"
                onClick={handleOpenProfile}
              >
                Edit Profile
              </Button>
            </Grid>
          </Grid>
          <Grid container className="myAccount-leftRight">
            <Grid item xs={12} sm={3} className="d-flex">
              <Box className="myaccount-left border-radius">
              <Box className="hiddenSM">
              <Box sx={{p:2}}>
                <span onClick={handleToggleShow} className="subMenuArrow d-flex">
                  Menu <MenuIcon className="ml-auto" />
                </span>
                </Box>
                <SlideToggle toggleEvent={toggleEvent} collapsed>
                  {({ setCollapsibleElement }) => (
                    <div className="submenuContent w-100" ref={setCollapsibleElement}>
                      {myAccountOrderMenu}
                    </div>
                  )}
                </SlideToggle> 
                </Box>

                <Box className="hiddenXS">
                  {myAccountOrderMenu}
                  </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} className="d-flex">
              <Box className="text-center w-100" sx={{p:3}}>
                <p>
                  You don’t have any active orders. Place your first order now!
                </p>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
      <Modal
        className="modalPopupTxt"
        open={openProfile}
        onClose={handleCloseProfile}
      >
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Button className="closepopup" onClick={handleCloseProfile}>
              <CloseIcon />
            </Button>
            <h2 className="mainHeading" id="modal-modal-title">
              Edit Profile
            </h2>

            <FormControl fullWidth className="mt-20">
              <label>Your Name</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="Normal"
              />
            </FormControl>

            <FormControl fullWidth className="mt-20">
              <label>Phone Number</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="510-554-4468"
              />
            </FormControl>

            <FormControl fullWidth className="mt-20">
              <label>E-mail</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="RobertHBeadle@jourrapide.com"
              />
            </FormControl>

            <Button className="btn primary-btn w-100 mt-15" variant="contained">
              Save
            </Button>
          </Box>{" "}
        </Box>
      </Modal>
    </Box>
  );
}
