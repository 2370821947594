import * as React from 'react'; 
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { authActions, store } from '_store';
import Box from '@mui/material/Box';   
import Grid from '@mui/material/Grid';     
import Select from '@mui/material/Select';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';  
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar';  
import { Footer } from 'footer/Footer'; 
import { NavLink } from 'react-router-dom'; 
import './profile.scss'; 
import { Button } from '@mui/material'; 
import shopLogodemo from "images/shopLogoDemo.png";
import demoUserImg from "images/demo-userimg.jpg";

export { Profile }; 
 

function Profile() { 
  const [profileObj, setProfileObj] = useState();
  const { user: authUser } = useSelector((x) => x.auth); 
  if (!authUser) {
    // not logged in so redirect to SignIn page with the return url
    history.navigate('/signin');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.getProfileData()); 
  }, []);

  const { getProfileData: userData } = useSelector((x) => x.auth);
   
  return ( 
    <Box sx={{ display: 'flex' }}>
    <Sidebar/> 
    <Box className="dashboardRightcontent profleInfo"> 
  {userData &&  
  <Box className="profilelightBG">
             <Box className="profilelayout">
               
               
                
                
                <Box className="d-flex"><NavLink className="editProfile ml-auto d-flex" to="edit-profile" userData={userData} ><ModeEditOutlineOutlinedIcon/> Edit</NavLink></Box>
                <Box>
                <h3 className='font-small fw-700 bColor'>Shop Photo</h3>
                <Box className="shopBannerImg">
                <Button component="label" className='browseBtnOuter'><span className='browseTxt'>Drop your photo here or <b>Browse</b></span>
                <small className='photoSizetxt'>Max Photo Size: 20MB</small>
                 <input type="file" hidden /></Button> 
                </Box>
               </Box>

               <Grid container spacing={2}>

               <Grid item xs={12} sm={6} lg={6}>
                 <h3 className='font-small fw-700 bColor pb-10'>Shop Logo</h3>
                 <Box className="shopLogoImg mb-20">
                  <span className='shopLogo'> <img src={shopLogodemo}  /></span>
                  <Button component="label" className='gradients-bg btn'>Choose Image
                 <input type="file" hidden /></Button> 
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
               <h3 className='font-small fw-700 bColor pb-10'>User Profile</h3>
               <Box className="shopLogoImg mb-20">
                  <span className='shopUserImg'> <img src={demoUserImg}  /></span>
                  <Box><Button component="label" className='gradients-bg btn'>Choose Image
                 <input type="file" hidden /></Button> <br/>
                 <Button className='outline-btn btn mt-10'>Delete</Button> </Box>
                </Box>
                </Grid>

               </Grid>
                
                <Grid container className='profileInfoOuter'> 
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Name</label>
                         <span>{userData.firstName} {userData.lastName}</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Email</label>
                         <span>{userData.emailAddress}</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Phone</label>
                         <span>N/A</span>
                        </Grid> 

                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Address</label>
                         <span>351 Harter Street</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>City</label>
                         <span>Dayton</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>State</label>
                         <span>Casa Grande</span>
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Zip Code</label>
                         <span>569852</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Account Holder Name</label>
                         <span>{userData.firstName} {userData.lastName}</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Account Number</label>
                         <span>510-554-4468</span>
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4}>
                         <label>IFSC Code</label>
                         <span>510-554-4468</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Shop Open and Close Timing</label>
                         <span>09:00 am TO 09:00 am</span>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                         <label>Sunday</label>
                         <span>Close</span>
                        </Grid>

                        </Grid> 
                    </Box>
                     </Box>
}
            <Footer /> 
         </Box> 
      </Box>
    );
}