import * as React from 'react'; 
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';
import Box from '@mui/material/Box'; 
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';  
import Grid from '@mui/material/Grid';   
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SortOutlinedIcon from '@mui/icons-material/SortOutlined';
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar'; 
import { Footer } from 'footer/Footer';
import './order.scss';   

export default AllOrder;

const columns = [
    { id: 'sno', label: '#', labelicon:<SortOutlinedIcon/>, minWidth: 50 },
    { id: 'name', label: 'Name', labelicon:<SortOutlinedIcon/>, minWidth: 170 },
    { id: 'phone', label: 'Phone', labelicon:<SortOutlinedIcon/>, minWidth: 100 },
    { id: 'orderid', label: 'Order ID', labelicon:<SortOutlinedIcon/>, minWidth: 100,},
    { id: 'date', label: 'Date', labelicon:<SortOutlinedIcon/>, minWidth: 170, },
    { id: 'email', label: 'Email', labelicon:<SortOutlinedIcon/>, minWidth: 170,},
    { id: 'address', label: 'Address', labelicon:<SortOutlinedIcon/>, minWidth: 170, },
  ];
  
  function createData(sno,name, phone, orderid, date, email, address) { 
    return { sno, name, phone, orderid, date, email, address };
  }
   
  function AllOrder() {   
    console.log("xcccccccccccccccccccc")
  const { user: authUser } = useSelector((x) => x.auth); 
  if (!authUser) { 
    history.navigate('/signin');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(shopActions.getAllOrders()); 
  }, []);

  const { getAllOrders: orderData } = useSelector((x) => x.shop);
    
  const [page, setPage] = React.useState(0);
  const [AllRows, seRows] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
  }; 
console.log(orderData,"sss")
useEffect(() => { 
  var rows = [
    createData('1', 'Dustin S. Hayes', '815-379-4001', '#6798', '15-05-2022 12:30 pm','DustinSHayes@armyspy.com','Mozilla/5.0 (Windows NT 10.0; WOW64'), 
   
  ]
  if(orderData && typeof orderData.data != "undefined"  && !orderData.data.message && !orderData.data.error){

    orderData.data.map(function (object, i) {
      var name = object.firstName;
      var emailAddress = object.emailAddress;
      var oid = object.id;
     var createdAt = object.createdAt;
      rows[i] =  createData(i,  name, "89897777", oid, createdAt, emailAddress, 'Addresss test' );
    });
    seRows(rows);
  }
 
 
}, [orderData]);


  return ( 
 
    <Box sx={{ display: 'flex' }}>
    <Sidebar/> 
    { AllRows &&
    <Box className="dashboardRightcontent"> 
          <Box className='filter-bg'>

          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} lg={3}>
              <span className='search-bg'>
              <IconButton type="button" sx={{ p: '0px' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search anything..." inputProps={{ 'aria-label': 'search anything...' }} />
              </span>
             </Grid>
             
          </Grid>
      </Box> 

    <Box className='orderTablelayout'>
      <TableContainer className='orderTable' sx={{ maxHeight:756 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.label}{column.labelicon}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {AllRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination className="tablebottombar" rowsPerPageOptions={[10, 15, 100]}
        component="div"
        count={AllRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> 
    </Box>
     
      <Footer />
      </Box>
  }
      </Box>  
            
    );
}