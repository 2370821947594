import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';

// create slice 
const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

// implementation

function createInitialState() { 
  //  const baseUrl = `${process.env.REACT_APP_API_URL}/api/shop`;  
   // const responseData =  createAsyncThunk(
      //  ``,
      //  async ({ emailAddress, password }) => await fetchWrapper.get(`${baseUrl}/login`, { emailAddress, password })
  //  );

    return { 
        //initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.user = null;
        localStorage.removeItem('user');
        history.navigate('/signin');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/shop`;  
    return {
        signin: signin(),
        getProfileData: getProfileData()
    };    

    function signin() {
        return createAsyncThunk(
            `${name}/signin`,
            async ({ emailAddress, password }) => await fetchWrapper.post(`${baseUrl}/login`, { emailAddress, password })
        );
    }
    function getProfileData() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `getProfileData`,
            async () => {
                const res = await fetch(`${baseUrl}/get_profile_data`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
}

function createExtraReducers() {
    return {
        ...signin(),
        ...getProfileData()
    };

    function signin() {
        var { pending, fulfilled, rejected } = extraActions.signin;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload; 
                const baseUrl = `${process.env.REACT_APP_API_URL}/api/shop`;   
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                const requestOptions = {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer '+user.token } 
                };
                fetch(`${baseUrl}/get_shop_category`, requestOptions)
                    .then(async response => {
                        const data = await response.json(); 
                        if(data.length > 0){
                            history.navigate("/"); 
                        }
                        else{
                            window.location.href = "/menu";

                           // history.navigate("/menu");
                        }
                        // check for error response
                        if (!response.ok) {
                            history.navigate("/"); 
                
                        }
                 
                    })
                    .catch(error => {
                        history.navigate("/"); 
                        console.error('There was an error!', error);
                    }); 
               
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function getProfileData() {
        var { pending, fulfilled, rejected } = extraActions.getProfileData;
        return {
            [pending]: (state) => {
                state.getProfileData = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getProfileData = action.payload;
            },
            [rejected]: (state, action) => {
                state.getProfileData = { error: action.error };
            }
        };
    }
}
