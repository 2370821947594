import { Box, Container, Grid, List, ListItem } from "@mui/material";
import * as React from "react";
import { NavLink } from "react-router-dom";
import SlideToggle from "react-slide-toggle";
import MenuIcon from "@mui/icons-material/Menu";
import { Footer } from "footer/Footer";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import RingVolumeOutlinedIcon from '@mui/icons-material/RingVolumeOutlined';
import helpDemovideo from "images/helpDemovideo.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export { Support };

function Support() {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);

  };

  const [toggleEvent, setToggleEvent] = React.useState(0);

  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };
  const myAccountOrderMenu = (
    <List>
      <ListItem>
        <NavLink to="/orderlist">Orders List</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/account-settings">Account Settings</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/support">Support</NavLink>
      </ListItem>
    </List>
  )


  return (

    <>
      <Box className="light-blue-bg">

        <Container fixed className="py-60 maxWidthLg">
          <Box className="white-bg border-radius-8 myaccountInfo">
            <Grid container className="d-flex profileBtnRow">
              <Grid item xs={12} sm={8}>
                <h2 className="font-32 pColor pb-20">Hi, Mark Smith</h2>

                <p className="font-small d-flex flex-wrap orderphonemailInfo">
                  <span className="pr-25 d-flex pt-5 info">
                    <LocalPhoneOutlinedIcon className="mr-5" /> 510-554-4468
                  </span>
                  <span className="d-flex pt-5 info">
                    <LocalPostOfficeOutlinedIcon className="mr-5" />
                    <a href="mailto:marksmith@gmail.com">
                      marksmith@gmail.com
                    </a>
                  </span>
                </p>

              </Grid>

            </Grid>


            <Grid container className="myAccount-leftRight">
              <Grid item xs={12} sm={3} className="d-flex">
                <Box className="myaccount-left border-radius">


                  <Box className="hiddenSM">
                    <Box sx={{ p: 2 }}>
                      <span onClick={handleToggleShow} className="subMenuArrow d-flex">
                        Menu <MenuIcon className="ml-auto" />
                      </span>
                    </Box>
                    <SlideToggle toggleEvent={toggleEvent} collapsed>
                      {({ setCollapsibleElement }) => (
                        <div className="submenuContent w-100" ref={setCollapsibleElement}>
                          {myAccountOrderMenu}
                        </div>
                      )}
                    </SlideToggle>
                  </Box>

                  <Box className="hiddenXS">
                    {myAccountOrderMenu}
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                className="flex-wrap myaccountRight">


                <Box className='supporcontainer'>

                  <Box><img src={helpDemovideo} className='w-100' /></Box>
                  <Box className="d-flex supportInfo pt-20">
                    <span><RingVolumeOutlinedIcon /> Toll free <br />
                      <b><a className="font-subheading pColor" href="tel:0898989800">0898989800</a></b></span>
                    <span><MailOutlinedIcon /> Email<br />
                      <b><a className="font-subheading pColor" href="mailto:sale@shopmart.com">sale@shopmart.com</a></b>
                    </span>
                  </Box>

                </Box>

              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box className="white-bg py-50">
        <Container>
          <h2 className="font-36 bColor text-center pb-40">Frequently Asked Questions</h2>

          <Box className="accordionOuter">

            <Accordion className="accordionInnerOuter" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content" id="panel1bh-header" className="accordionHeading">
                <h2>
                  How much does it cost to post a job on the marketplace?
                </h2>

              </AccordionSummary>
              <AccordionDetails className="accordionInfo">
                <h2>
                  How much does it cost to post a job on the marketplace?
                </h2>
                <p>It costs $159 to post a job. For this, we’ll help you create a brief and select recruiters, consolidate communication with agencies, schedule interviews, and help with admin. Some clients like that we help them manage themselves!
                </p>
              </AccordionDetails>
            </Accordion>


            <Accordion className="accordionInnerOuter" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header" className="accordionHeading">
                <h2>What is the candidate's rebate period? </h2>

              </AccordionSummary>
              <AccordionDetails className="accordionInfo">
                <p>
                  The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordionInnerOuter" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header" className="accordionHeading">
                <h2>
                  What placement fee should I set to incentivise recruiters?
                </h2>

              </AccordionSummary>
              <AccordionDetails className="accordionInfo">
                <p>
                  On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordionInnerOuter" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header" className="accordionHeading">
                <h2>How many recruitment agencies should I engage on a role? </h2>
              </AccordionSummary>
              <AccordionDetails className="accordionInfo">
                <p>
                  But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion className="accordionInnerOuter" expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header" className="accordionHeading">
                <h2>What type of jobs get filled on the marketplace? </h2>
              </AccordionSummary>
              <AccordionDetails className="accordionInfo">
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.
                </p>
              </AccordionDetails>
            </Accordion>



          </Box>
        </Container>
      </Box>

      <Footer />

    </>



  );
};
