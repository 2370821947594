import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { commonActions, authActions, store } from '_store';
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import * as React from "react";
import demoVector from "images/demo-img.png";
import silderImage from "images/homepage-slider01.png";
import EssentialsImg from "images/essentialsImg.png";
import TopRatedImg from "images/TopRated-Img.png";
import StarIcon from '@mui/icons-material/Star';
import playstoreIcon from "images/playstoreIcon.png";
import appstoreIcon from "images/appstoreIcon.png";
import createAccount from "images/create-account-Group.png";
import "./homepage.scss";
import { NavLink } from "react-router-dom";
import { Footer } from "footer/Footer";
export { Home };

function Home() {
  const dispatch = useDispatch();
  const { globalCategories: AllCategories } = useSelector((x) => x.common);
  const { topRatedShops: TopRatedShopsList } = useSelector((x) => x.common);
  const [categoryList, setCategoryList] = useState([]);
  const [shopList, setShopList] = useState([]);

  useEffect(() => {
    dispatch(commonActions.globalCategories());
    dispatch(commonActions.topRatedShops());
  }, []);

  useEffect(() => { 
    if (typeof AllCategories != "undefined" && AllCategories && !AllCategories.loading) {
      setCategoryList(AllCategories)
    }
  }, [AllCategories]);
  useEffect(() => { 
    if (typeof TopRatedShopsList != "undefined" && TopRatedShopsList && !TopRatedShopsList.loading) {
      setShopList(TopRatedShopsList)
    }
  }, [TopRatedShopsList]); 
  return (
    <Box>
      <Box className="HomepageBanner">
        {/* <Grid container spacing={0} className="container">
          <Grid item xs={12} sm={6}>
            <h1>
              BANNER <span>TEXT</span>
            </h1>
          </Grid>
          <Grid item className="bannerImage" xs={12} sm={6}>
            <img src={demoVector} className="w-100" />
          </Grid>
        </Grid> */}
        <img src={silderImage} />
      </Box>

      <Box className="container py-60 essentialsList">
        <h2 className="mainHeading">Essentials</h2>
        <Grid container spacing={[3, 6, 6]} className="text-center">
          {categoryList && Object.keys(categoryList).length > 0 &&
            categoryList.map(function (object, i) {
              return <Grid item xs={6} sm={3}>
                <NavLink to={`/shop/${object.id}`} state={{ category: object }}>
                <Box className="lightGreyBG">
                  <img src={EssentialsImg} className="w-100" /></Box>
                  <h3>{object.categoryName}</h3>
                </NavLink>
              </Grid>;
            })}
        </Grid>
      </Box>

      <Box className="TopRatedShops">
        <Box className="container py-60 essentialsList">
          <h2 className="mainHeading text-center">Top Rated Shops</h2>
          <Grid container spacing={[3, 4, 4]} className="text-center">
          {shopList && Object.keys(shopList).length > 0 &&
            shopList.map(function (object, i) {
              return  <Grid item xs={6} sm={3}> 
              <Box className="white-bg p-25 border-radius-8">
              <img src={TopRatedImg} className="w-100" /> 
              <h3>{object.title}</h3>
              <Box className="rateingInfo pt-5">
              <StarIcon/>
              <StarIcon/>
              <StarIcon/>
              <StarIcon/>
              <StarIcon/>
              </Box>
              </Box>
            </Grid>;
            })}
 
          </Grid>
        </Box>
      </Box>

      <Box className="container py-60 downloadApp homeCAsection">
        <Grid container spacing={[3, 6]} className="align-items-cenrer justify-content-cenrer ">
          <Grid className="createAccountImg" item xs={12} sm={7}>
            <img src={createAccount} className="w-100" />
          </Grid>
          <Grid className="createAccountInfo" item xs={12} sm={5}>
            <h3 className="mainHeading pb-20">
            Create account
            </h3>
            <p className="pb-20">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when<br/>
             an unknown printer took a galley of type and scrambled it to make a type ...</p>
             <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ...</p>
            <Box className="mt-20 d-flex">
               <NavLink to="#" className="btn shopBtn">Shop Now</NavLink>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
      {/* {users.length && (
        <ul>
          {users.map((user) => (
            <li key={user.id}>
              {user.firstName} {user.lastName}
            </li>
          ))}
        </ul>
      )}
      {users.loading && (
        <div className="spinner-border spinner-border-sm"></div>
      )}
      {users.error && (
        <div className="text-danger">
          Error loading users: {users.error.message}
        </div>
      )} */}
    </Box>
  );
}
