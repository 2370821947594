import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar';
import './menu.scss';
import Strawberry from 'images/strawberry.png';
import Orange from 'images/orange.png';
import Grapes from 'images/grapes.png';
import { Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import demoImg from "images/demo-img.png";

import { Footer } from 'footer/Footer';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export { MenuCategories };

function MenuCategories() {
  const [currentCatName, setCurrentCatName] = useState("");
  const [categoryListShop, setCategoryListShop] = useState();
  const [productQuantityObj, setProductQuantity] = useState([]);
  const [productPriceObj, setProductPrice] = useState([]);
  const [currentObjKey, setCurrentObjKey] = useState();
  const [currentCatId, setCurrentCatId] = useState();
  const [productListShop, setProductListShop] = useState([]);

  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { shopCategories: shopCategoryList } = useSelector((x) => x.shop);
  const { GetProductByGlobalCategory: ProductByGlobalCategoryList } = useSelector((x) => x.shop);

  if (!authUser) {
    // not logged in so redirect to SignIn page with the return url
    history.navigate('/signin');
  }


  const [openAddProduct, setOpenAddProduct] = React.useState(false);
  const handleOpenAddProduct = () => setOpenAddProduct(true);
  const handleCloseAddProduct = () => setOpenAddProduct(false);



  const [openPrice, setOpenPrice] = React.useState(false);
  const handleOpenPrice = (object, i) => {
    console.log(object.price)
    setAddPrice(object.price);
    setProductPrice(object);
    setCurrentObjKey(i);
    setOpenPrice(true);

  }
  const handleClosePrice = () => setOpenPrice(false);

  const [openQty, setOpenQty] = React.useState(false);
  const handleOpenQty = (object, i) => {
    setProductQuantity(object);
    setCurrentObjKey(i);
    setOpenQty(true);
  }
  const handleCloseQty = () => setOpenQty(false);

  const [thisweek, setthisweek] = React.useState('');
  const handleChange = (event) => {
    setthisweek(event.target.value);
  };
  const [filter, setfilter] = React.useState('');
  const handleChangefilter = (event) => {
    setfilter(event.target.value);
  };
  const addQuantity = () => {
    var tempProps = JSON.parse(JSON.stringify(productListShop));
    tempProps[currentObjKey].quantity = AddQty;
    Object.preventExtensions(tempProps);
    setProductListShop(tempProps);
    // console.log(AddQty, currentObjKey, typeof productListShop, tempProps )
    setOpenQty(false);
  };

  const addPrice = () => {
    var tempProps = JSON.parse(JSON.stringify(productListShop));
    tempProps[currentObjKey].price = AddPrice;
    Object.preventExtensions(tempProps);
    setProductListShop(tempProps);
    // console.log(AddQty, currentObjKey, typeof productListShop, tempProps )
    setOpenPrice(false);
  };

  const [AddPrice, setAddPrice] = React.useState('');
  const handleChangeAddPrice = (event) => {
    setAddPrice(event.target.value);
  };
  const [AddQty, setAddQty] = React.useState('');
  const handleChangeAddQty = (event) => {
    setAddQty(event.target.value);
  };
  useEffect(() => {
    dispatch(shopActions.shopCategories());

  }, []);

  useEffect(() => {
    if (shopCategoryList && !shopCategoryList.loading) {
      setCategoryListShop(shopCategoryList.data)
    }
    if (ProductByGlobalCategoryList && !ProductByGlobalCategoryList.loading) {
      setProductListShop(ProductByGlobalCategoryList.data)
    }
  }, [shopCategoryList, ProductByGlobalCategoryList]);

  function SubCategoryList(params) {
    return params.subCategories.map(function (object, i) {
      return <ListItem> <a onClick={() => {
        setCurrentCatId(object.id);
        setCurrentCatName(object.categoryName);
        dispatch(shopActions.GetProductByGlobalCategory(object.globalCategoryId))
      }
      } > {object.categoryName}<ArrowForwardIosOutlinedIcon /></a> </ListItem>
    })

  }
  const handleChangeCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(currentCatId, "zzzzzzzzzzzzzzzzz")
    var tempProps = JSON.parse(JSON.stringify(productListShop));
    tempProps[event.target.value].checked = event.target.checked;
    tempProps[event.target.value].categoryId = currentCatId;
    Object.preventExtensions(tempProps);
    setProductListShop(tempProps);
    var productObj = productListShop[event.target.value];
    if (event.target.checked) {
      dispatch(shopActions.copyShopProduct(tempProps[event.target.value]));
    }
    else {
      const productObjRequest = { productId: productObj.id, status: 0 };
      dispatch(shopActions.updateProductStatus(productObjRequest));
    }

  };
  function GlobalProductsList() {
    return (
      <>
        {productListShop && Object.keys(productListShop).length === 0 &&
          <Grid container className="CatlistRow">
            <Grid item xs={12} sm={12} lg={12} className="catCol">
              <Box className="productimgTxt pt-20 pb-20">
                Product not found.
              </Box></Grid></Grid>
        }
        {productListShop && Object.keys(productListShop).length > 0 && productListShop.map(function (object, i) {
          return <Grid container className="CatlistRow">
            <Grid item xs={12} sm={6} lg={6} className="catCol">
              <Box className="productimgTxt">
                <Box className="productimg"><img src={object.productImage} /></Box>
                <Box className="productinfo">
                  <h3>{object.productTitle}</h3>
                  <span className='popupTxt' onClick={() => handleOpenQty(object, i)}>
                    {object.quantity && typeof (object.quantity) != "undefined" &&
                      <>{object.quantity}</>
                    }
                    {!object.quantity &&
                      <>Select Quantity</>
                    }
                    <KeyboardArrowDownOutlinedIcon /></span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sm={2} lg={2} className="catCol text-center pr-15">
              <span className="hiddenSM iPhoneTxt">Price</span>

              <span className='priceBtn'>
                {object.price && typeof (object.price) != "undefined" &&
                  <>$ {object.price}</>
                }

                {object.price && typeof object.price !== "undefined" ? "" : <span className='popupTxt addPricetxt' onClick={() => handleOpenPrice(object, i)}>Add Price</span>}

              </span>
            </Grid>
            <Grid item xs={4} sm={2} lg={2} className="catCol text-center">
              <span className="hiddenSM iPhoneTxt">Stock</span>
              <span className="checkBtn">
                {(object.price && object.quantity) ? <Switch  {...label} value={i} checked={object.checked} onChange={(e) => { handleChangeCheck(e) }} inputProps={{ 'object': object }} /> : <Switch disabled {...label} />}
              </span>
            </Grid>
            <Grid item xs={4} sm={2} lg={2} className="catCol text-center">
              <span className="hiddenSM iPhoneTxt">Action</span>


              <span className='popupTxt addPricetxt' onClick={() => handleOpenPrice(object, i)}>
                <ModeEditOutlineOutlinedIcon /> {(object.price && typeof (object.price) != "undefined") ? " Edit" : "Edit"}
              </span>


            </Grid>
          </Grid>
        })
        }

      </>
    )
  }


  return (

    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box className="dashboardRightcontent">
        <Box className='filter-bg'>
          <Grid container columnSpacing={2} rowSpacing={{ xs: 2, sm: 0 }}>
            <Grid item xs={12} sm={5} lg={3}>
              <span className='search-bg'>
                <IconButton type="button" sx={{ p: '0px' }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search anything..." inputProps={{ 'aria-label': 'Search anything...' }} />
              </span>
            </Grid>


            <Grid item xs={12} sm={7} lg={4} className="ml-auto d-flex">
              <Button onClick={handleOpenAddProduct} className="btn primary-btn ml-auto"><AddOutlinedIcon className="mr-5" /> Add Product</Button>

              <NavLink to="/menu" className="ml-10"><Button className='white-btn btn pl-0' variant="contained" size="lager" sx={{ whiteSpace: 'nowrap' }}><ModeEditOutlineOutlinedIcon className="mr-5" /> Edit Category</Button></NavLink>
              {/* <FormControl fullWidth className='customselect mr-15'>
                <Select className='form-control' value={thisweek} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without abel' }} >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={1}>Last Week</MenuItem>
                  <MenuItem value={2}>Last Month</MenuItem>
                  <MenuItem value={2}>This Month</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth className='customselect custom-filter'>
                <Select className='form-control' value={filter} onChange={handleChangefilter} displayEmpty inputProps={{ 'aria-label': 'Without abel' }} >
                  <MenuItem value="">Filter</MenuItem>
                  <MenuItem value={1}>Last Week</MenuItem>
                  <MenuItem value={2}>Last Month</MenuItem>
                  <MenuItem value={2}>This Month</MenuItem>
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>
        </Box>

        <Box className="menucatProductlayout">
          <Grid container spacing={0} pt={3} className="menuProductRow">

            <Grid item xs={12} sm={12} lg={3} className="menuCatleft">
              <List className="menuCatlist-bg">
                {typeof categoryListShop != "undefined" && categoryListShop && categoryListShop.map(function (object, i) {
                  return <><ListItem className='listHeading'><a>{object.categoryName}<KeyboardArrowDownOutlinedIcon /></a></ListItem> <SubCategoryList subCategories={object.sub} /></>;

                })}

              </List>
            </Grid>

            <Grid item xs={12} sm={12} lg={9} className="menuCatright">
              <Box className="CatlistInfo">
                <h3 className='font-large bColor pt-5'>{currentCatName}</h3>
                <Grid container className="catlistHeader hiddenXS">
                  <Grid item xs={12} sm={6} lg={6} className="catCol">
                    <span>Items</span>
                  </Grid>
                  <Grid item xs={12} sm={2} lg={2} className="catCol text-center">
                    <span className='pr-15'>Amount</span>
                  </Grid>
                  <Grid item xs={12} sm={2} lg={2} className="catCol text-center">
                    <span>Stock</span>
                  </Grid>
                  <Grid item xs={12} sm={2} lg={2} className="catCol text-center">
                    <span>Action</span>
                  </Grid>
                </Grid>

                <GlobalProductsList />

              </Box>
            </Grid>

          </Grid>
        </Box>
        <Footer />
      </Box>


      <Modal className="modalPopupTxt modalAddProduct" open={openAddProduct}
        onClose={handleCloseAddProduct} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Box className="modalHeading">
              <Button className="closepopup" onClick={handleCloseAddProduct}><CloseIcon /></Button>
              <h2 className="gColor font-subheading pl-30" id="modal-modal-title">Add new product</h2>
            </Box>


            <Grid container spacing={3} className='mt-0'>

              <Grid item xs={12} sm={12} lg={12}>
                <Box className="addProductImg">
                  <Button component="label" className='browseBtnOuter'>
                  <img src={demoImg}  />
                    <span className='browseTxt w-100 pt-15 font-small'>Drop your image here, or browse <span className='font-xxs fw-400 w-100 d-block pt-5'>Support: JPG, JPEG, PNG</span></span>
                    <small className='photoSizetxt w-100 pt-20 pColor'>Browse</small>
                    <input type="file" hidden /></Button>
                </Box>
              </Grid>


              <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth>
                  <label>Product Name</label>
                  <TextField className='form-control' hiddenLabel id="filled-hidden-label-normal" placeholder="Type Name" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth>
                  <label>Select Unit</label>
                  <Select className='form-control' value={thisweek} onChange={handleChange} displayEmpty inputProps={{ 'aria-label': 'Without abel' }} >
                    <MenuItem value="">Select</MenuItem>
                    <MenuItem value={1}>Product 01</MenuItem>
                    <MenuItem value={2}>Product 02</MenuItem>
                    <MenuItem value={2}>Product 03</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl fullWidth>
                  <label>Price</label>
                  <TextField className='form-control' hiddenLabel id="filled-hidden-label-normal" placeholder="$" />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <Button className='btn gradients-bg btn-h-40 w-100 mt-30 mt-xs-0' variant='contained'>Add Product</Button>
              </Grid>
            </Grid>




          </Box>
        </Box>
      </Modal>


      <Modal className="modalPopupTxt modalAddQuantity" open={openPrice} onClose={handleClosePrice} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Box className="modalHeading">
              <Button className="closepopup" onClick={handleClosePrice}><CloseIcon /></Button>
              <h2 className="bColor font-subheading text-center" id="modal-modal-title">Add Price</h2>
            </Box>
            <Box className="productimgTxt">
              <Box className="productimg"><img src={productPriceObj.productImage} /></Box>
              <Box className="productinfo w-100 text-center">
                <h3>{productQuantityObj.productTitle}</h3>
                <span className='popupTxt w-100'><b>Quantity: </b> {productPriceObj.quantity && typeof (productPriceObj.quantity) != "undefined" &&
                  <>{productPriceObj.quantity}</>
                }
                  {!productPriceObj.quantity &&
                    <>N/A</>
                  } </span>
              </Box>
            </Box>
            <FormControl fullWidth className='mr-15'>
              <label>Add Price</label>
              <TextField className='form-control' hiddenLabel id="filled-hidden-label-normal" onChange={handleChangeAddPrice} placeholder="Normal" value={AddPrice} />
            </FormControl>
            <Button className='btn gradients-bg btn-h-40 w-100 mt-15' variant='contained' onClick={addPrice}>Request Changes</Button>
            <p className='font-small pt-10'>Price edits raise a request to Shopmart catalog support team. you can view
              them from ‘Approval Request’ section.</p>
          </Box>
        </Box>
      </Modal>

      <Modal className="modalPopupTxt modalAddQuantity" open={openQty} onClose={handleCloseQty} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        {productQuantityObj &&
          <Box className="modalPopupOverflow">
            <Box className="modalPopupTxt-inner">

              <Box className="modalHeading">
                <Button className="closepopup" onClick={handleCloseQty}><CloseIcon /></Button>
                <h2 className="bColor font-subheading text-center" id="modal-modal-title">Add Quantity</h2>
              </Box>

              <Box className="productimgTxt">
                <Box className="productimg"><img src={productQuantityObj.productImage} /></Box>
                <Box className="productinfo w-100">
                  <h3>{productQuantityObj.productTitle}</h3>
                </Box>
              </Box>
              <FormControl fullWidth className='customselect mr-15'>
                <label>Add Quantity</label>
                <Select className='form-control' value={AddQty} onChange={handleChangeAddQty} displayEmpty inputProps={{ 'aria-label': 'Without abel' }} >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="500 GM">500 GM</MenuItem>
                  <MenuItem value="1 KG">1 KG</MenuItem>
                  <MenuItem value="2 KG">2 KG</MenuItem>
                  <MenuItem value="3 KG">3 KG</MenuItem>
                  <MenuItem value="4 KG">4 KG</MenuItem>
                </Select>
              </FormControl>
              <Button className='btn gradients-bg btn-h-40 w-100 mt-15' variant='contained' onClick={addQuantity}>Add</Button>
            </Box>
          </Box>
        }
      </Modal>

    </Box>
  );
}