import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Header } from '../header/Header';
import { history } from '_helpers';

export { PublicHeader };

function PublicHeader({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    
    if (!authUser) {
        // not logged in so redirect to SignIn page with the return url
        return <Header />
    }

    // authorized so return child components
    return children;
}
 