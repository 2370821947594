import * as React from "react";
import { useReducer } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";
import { UserReducer } from "../../_reducers/usersReducer";
import { authActions, CommonReducer } from "_store";
import Box from "@mui/material/Box";
import { NavLink, useLocation } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem"; 
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"; 
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"; 
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import demoUserImg from "images/demo-userimg.jpg";
import SlideToggle from "react-slide-toggle";


import "./sidebar.scss";

const drawerWidth = 240;
const Sidebarlinks = [
  {
    iconlabel: <HomeOutlinedIcon className="icon" />,
    to: "/",
    label: "Dashboard",
  },
  {
    iconlabel: <AccountBoxOutlinedIcon className="icon" />,
    to: "/profile",
    label: "Profile",
  },
  {
    iconlabel: <AssignmentOutlinedIcon className="icon" />,
    to: "/menu",
    label: "Menu",
  },
  {
    iconlabel: <LocalMallOutlinedIcon className="icon" />,
    to: "/orders",
    label: "Orders",
    submenu: [
      {
        to: "/orders/new",
        label: "New",
      },
      {
        to: "/orders/preparing",
        label: "Preparing",
      },
      {
        to: "/orders/ready",
        label: "Ready",
      }
    ],
  },

  {
    iconlabel: <HeadsetMicOutlinedIcon className="icon" />,
    to: "/help",
    label: "Enquiry",
  },
  {
    iconlabel: <HandshakeOutlinedIcon className="icon" />,
    to: "/subscription",
    label: "Subscription",
  },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10.5)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "opensidebar",
})(({ theme, opensidebar }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(opensidebar && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "opensidebar",
})(({ theme, opensidebar }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(opensidebar && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!opensidebar && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export { Sidebar };

function Sidebar() {
  const location = useLocation();
  const pageTitle = (location.state && typeof location.state.title != "undefined") ? location.state.title : "Dashboard";
  const [state, dispatchState] = useReducer(UserReducer, {})

  const authUser = useSelector((x) => x.auth.user);
  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());
  const theme = useTheme();
  const sidebarOpenToggle = (localStorage.getItem("menuSidebar") == "open") ? true : false;
  const [opensidebar, sideBarOpen] = React.useState(sidebarOpenToggle);
  const [toggleEvent, setToggleEvent] = React.useState(0);
  const [collapseEvent, setCollapseEvent] = React.useState(0);
  const [showToggleEvent, showToggle] = React.useState(sidebarOpenToggle);

  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };
  const data = useSelector((state) => state);
  const handleDrawerOpen = () => {
    sideBarOpen(true);
    showToggle(true);
    localStorage.setItem("menuSidebar", "open");
  };


  const handleDrawerClose = () => {
    sideBarOpen(false);
    setCollapseEvent(Date.now());
    showToggle(false);
    localStorage.setItem("menuSidebar", "close");


  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <AppBar position="fixed" className="siderbar-header" opensidebar={opensidebar}>
        <Toolbar>
          <IconButton
            className="menuicon"
            aria-label="opensidebar drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 5, ...(opensidebar && { display: "none" }) }}
          >
            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 13.5V11.875H5.5V13.5H0.5ZM0.5 7.8125V6.1875H10.5V7.8125H0.5ZM0.5 2.125V0.5H15.5V2.125H0.5Z" fill="#0070BC" />
            </svg>
          </IconButton>
          <Typography noWrap component="div">
            <h2 className="mainHeading">{pageTitle}</h2>
          </Typography>

          <Box className="ml-auto sidebar-headerright d-flex">
            <IconButton
              className="mui-icon"
              size="large"
              aria-label="show 10 new notifications"
            >
              <Badge badgeContent={5} color="error" className="iconbadge">
                <NotificationsNoneOutlinedIcon />
              </Badge>
            </IconButton>

            <span
              className="profile-menuicon"
              aria-controls={openProfile ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
              onClick={handleClickProfile}
            >
              {/* <span className="userName">ST</span> */}
              <div className="userNamimage"><img src={demoUserImg}  /> Singh traders</div>
              <KeyboardArrowDownOutlinedIcon className="arrowdown" />
            </span>
            <Menu
              className="profileSubmenu"
              anchorEl={anchorEl}
              open={openProfile}
              onClose={handleCloseProfile}
            >
              <ListItem>
                <NavLink to="/profile">Profile</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/profile/edit-profile">Edit Profile</NavLink>
              </ListItem>
              <ListItem onClick={logout}>Logout</ListItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" className="sidebar" opensidebar={opensidebar}>
        <DrawerHeader className="logo-menuicon">
          <IconButton
            className="menuicon closemenu-icon"
            onClick={handleDrawerClose}
          >

            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.5 13.5V11.875H5.5V13.5H0.5ZM0.5 7.8125V6.1875H10.5V7.8125H0.5ZM0.5 2.125V0.5H15.5V2.125H0.5Z" fill="white" />
            </svg>

          </IconButton>
        </DrawerHeader>

<Box className="sidebarInnerArea">
      <Box className="usershortName">
        <span className="shortletter">ST</span>
        <Box className="userwelcome">Welcome <br/>
         Singh traders</Box>
        </Box>

        <List className="sidebarlinks">
          {Sidebarlinks.map((link, i) => (
            <ListItem key={i}>
              <NavLink state={{ title: link.label }} to={{
                pathname: link.to,
                aboutProps: {
                  sidebarOpened: opensidebar
                }
              }} sidebarOpen={opensidebar}>
                <span className="menuItem">{link.iconlabel} {link.label}</span>
              </NavLink>
              {link?.submenu?.length > 0 && (
                <>
                  {showToggleEvent &&
                    <span onClick={handleToggleShow} className="subMenuArrow">
                      <ArrowForwardIosOutlinedIcon className="arrow" />
                    </span>
                  }
                  <SlideToggle toggleEvent={toggleEvent} collapsed collapseEvent={collapseEvent}>
                    {({ setCollapsibleElement }) => (
                      <div className="submenuContent w-100" ref={setCollapsibleElement}>
                        <List className="submenu">
                          {link.submenu &&
                            link.submenu.map((item) => (
                              <ListItem>
                                <NavLink to={item.to} state={{ from: "occupation", refer: item.label }}>
                                  {item.label} <span className="badge">2</span>
                                </NavLink>
                              </ListItem>
                            ))}
                        </List>
                      </div>
                    )}
                  </SlideToggle>
                </>
              )}
            </ListItem>
          ))}
        </List>
        </Box>
      </Drawer>
    </Box>
  );
}
