import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '_helpers';

// create slice

const name = 'shops';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const shopActions = { ...slice.actions, ...extraActions };
export const shopReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        users: {} 
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/shop`;
    return {
        createUser: createUser(),
        globalCategories: globalCategories(),
        saveCategories: saveCategories(), 
        shopCategories: shopCategories(),
        GetProductByGlobalCategory : GetProductByGlobalCategory(),
        getAllOrders:getAllOrders(),
        copyShopProduct:copyShopProduct(),
        updateProductStatus:updateProductStatus(),
        loadProductsByShopCategories:loadProductsByShopCategories(),
        getOrderDetail:getOrderDetail(),
        updateOrderStatus: updateOrderStatus()
    };
  
    function createUser() {
        return createAsyncThunk(
            `/shop/register`,
            async (formData) => await fetchWrapper.post(baseUrl + "/register", formData)
        );
    }
    function saveCategories() { 
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        
        return createAsyncThunk(
            `save_categories`,
            async (selectedCategories) => {
                const res = await fetch(`${baseUrl}/save_categories`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token },
                    body:JSON.stringify({selectedCategories}) 
                }).then(
                (data) => data.json()
              )
              return res
                }
           // async (selectedCategories) => await fetchWrapper.post(baseUrl + "/save_categories", selectedCategories)
        );
        
    }
    function globalCategories() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `get_global_category`,
            async () => {
                const res = await fetch(`${baseUrl}/get_global_category`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function shopCategories() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `get_shop_category`,
            async () => {
                const res = await fetch(`${baseUrl}/get_shop_category`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function GetProductByGlobalCategory() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `get_products_by_global_cat`,
            async (globalCategoryId) => {
                const res = await fetch(`${baseUrl}/get_products_by_global_cat/${globalCategoryId}`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function getAllOrders() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        } 
        return createAsyncThunk(
            `get_all_orders`,
            async (params) => {
                const res = await fetch(`${baseUrl}/get_all_orders`, 
                {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token },
                    body:JSON.stringify(params) 
                }
                ).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function copyShopProduct() { 
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        
        return createAsyncThunk(
            `copy_shop_products`,
            async (productObj) => {
                const res = await fetch(`${baseUrl}/copy_shop_products`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token },
                    body:JSON.stringify({productObj}) 
                }).then(
                (data) => data.json()
              )
              return res
                }
           // async (selectedCategories) => await fetchWrapper.post(baseUrl + "/save_categories", selectedCategories)
        );
        
    }
    function updateProductStatus() { 
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        
        return createAsyncThunk(
            `update_product_status`,
            async (params) => {
                const res = await fetch(`${baseUrl}/update_product_status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token },
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                }
           // async (selectedCategories) => await fetchWrapper.post(baseUrl + "/save_categories", selectedCategories)
        );
        
    }
    function loadProductsByShopCategories() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `load_products_by_shop_categories`,
            async (id) => {
                const res = await fetch(`${baseUrl}/get_products_by_shop_categories/${id}`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function getOrderDetail() {
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `get_order_detail`,
            async (id) => {
                const res = await fetch(`${baseUrl}/get_order_detail/${id}`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function updateOrderStatus() { 
        const user = localStorage.getItem('user');
        if (!user) {
            return {};
        }
        
        return createAsyncThunk(
            `update_order_status`,
            async (params) => {
                const res = await fetch(`${baseUrl}/update_order_status`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token },
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        );
        
    }
}

function createExtraReducers() {
    return {
        ...createUser(),
        ...globalCategories(),
        ...saveCategories(),
        ...shopCategories(),
        ...GetProductByGlobalCategory(),
        ...getAllOrders(),
        ...copyShopProduct(),
        ...loadProductsByShopCategories(),
        ...getOrderDetail(),
        ...updateOrderStatus()
    };

    function createUser() {
        var { pending, fulfilled, rejected } = extraActions.createUser;
        return {
            [pending]: (state) => {
                state.users = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.users = { success: "User Registered Successfully." };
            },
            [rejected]: (state, action) => {
                state.users = { error: action.error };
            }
        };
    }
    function globalCategories() {
        var { pending, fulfilled, rejected } = extraActions.globalCategories;
        return {
            [pending]: (state) => {
                state.globalCategories = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.globalCategories = action.payload;
            },
            [rejected]: (state, action) => {
                state.globalCategories = { error: action.error };
            }
        };
    }
    function saveCategories() {
        var { pending, fulfilled, rejected } = extraActions.saveCategories;
        return {
            [pending]: (state) => {
               state.saveCategories = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.saveCategories = action.payload;
            },
            [rejected]: (state, action) => {
                 state.saveCategories = { error: action.error };
            }
        };
    }
    function shopCategories() {
        var { pending, fulfilled, rejected } = extraActions.shopCategories;
        return {
            [pending]: (state) => {
               state.shopCategories = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.shopCategories =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.shopCategories = { error: action.error };
            }
        };
    }
    
    function GetProductByGlobalCategory() {
        var { pending, fulfilled, rejected } = extraActions.GetProductByGlobalCategory;
        return {
            [pending]: (state) => {
               state.GetProductByGlobalCategory = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.GetProductByGlobalCategory =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.GetProductByGlobalCategory = { error: action.error };
            }
        };
    }
    function getAllOrders() {
        var { pending, fulfilled, rejected } = extraActions.getAllOrders;
        return {
            [pending]: (state) => {
               state.getAllOrders = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getAllOrders =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.getAllOrders = { error: action.error };
            }
        };
    }
    function copyShopProduct() {
        var { pending, fulfilled, rejected } = extraActions.copyShopProduct;
        return {
            [pending]: (state) => {
               state.copyShopProduct = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.copyShopProduct =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.copyShopProduct = { error: action.error };
            }
        };
    }
    function loadProductsByShopCategories() {
        var { pending, fulfilled, rejected } = extraActions.loadProductsByShopCategories;
        return {
            [pending]: (state) => {
               state.ShopProducts = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.ShopProducts =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.ShopProducts = { error: action.error };
            }
        };
    }
    function getOrderDetail() {
        var { pending, fulfilled, rejected } = extraActions.getOrderDetail;
        return {
            [pending]: (state) => {
               state.getOrderDetail = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getOrderDetail =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.getOrderDetail = { error: action.error };
            }
        };
    }
    function updateOrderStatus() {
        var { pending, fulfilled, rejected } = extraActions.updateOrderStatus;
        return {
            [pending]: (state) => {
               state.updateOrderStatus = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.updateOrderStatus =  { data: action.payload };
            },
            [rejected]: (state, action) => {
                 state.updateOrderStatus = { error: action.error };
            }
        };
    }

    
}
