import React, { useState, useEffect } from 'react';
import CartContext from './CartContext';

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [itemCount, setItemCount] = useState(0);


    useEffect(() => {
        console.log("time to runn contexttttttt")
        // Retrieve the cart from local storage during initialization
        const storedCart = localStorage.getItem('cartProducts');
        if (storedCart) {
            const parsedCart = JSON.parse(storedCart);
            if (Array.isArray(parsedCart)) {
                setCart(parsedCart);
                const amount = parsedCart.reduce((acc, item) => acc + 1 * item.productPrice, 0);
                setTotalAmount(amount);
            }
        }

    }, []);
    useEffect(() => {
        setItemCount(cart.length);
    }, [cart]);
    const addToCart = (item) => {
        setCart(prevData => {
            const updatedData = (prevData && prevData.length > 0 && prevData[0].shopId == item.shopId) ? [...prevData, item] : [item];
            localStorage.setItem('cartProducts', JSON.stringify(updatedData)); 
             const amount = updatedData.reduce((acc, item) => acc + 1 * item.productPrice, 0);
             setTotalAmount(amount);
            //const parsedCart = JSON.parse(updatedData);
            // if (Array.isArray(parsedCart)) { 
            //     const amount = parsedCart.reduce((acc, item) => acc + 1 * item.productPrice, 0);
            //     setTotalAmount(amount);
            // }
            return updatedData;
        });
    };

    const removeFromCart = (itemId) => {
        //setCart((prevCart) => prevCart.filter((item) => item.id !== itemId));
        setCart([]);
        localStorage.setItem('cartProducts', "");

    };

    // Other cart manipulation functions

    return (
        <CartContext.Provider value={{ cart, addToCart, removeFromCart, totalAmount, itemCount }}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
