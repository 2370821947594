import * as React from 'react';
import { useReducer, useEffect, useState } from "react";
import { shopActions, store } from "_store";
import { useSelector, useDispatch } from "react-redux";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { Footer } from 'footer/Footer';
import { Container } from '@mui/system';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import ShopSignicon1 from "images/shopSign-icon1.png";
import ShopSignicon2 from "images/shopSign-icon2.png";
import ShopSignicon3 from "images/shopSign-icon3.png";
import ShopSignicon4 from "images/shopSign-icon4.png";

import './shopsignup.scss';

export { ShopSignUp };

function ShopSignUp() {
    const dispatch = useDispatch();
    // const { user: authUser } = useSelector((x) => x.shop);
    const { users: shopUser } = useSelector((x) => x.shop);
    const RegisterError = useSelector((x) => x.shop.error);
    const success = useSelector((x) => x.shop.success);

    const [formdata, setFormData] = useState({
        firstName: "",
        address: "",
        city: "",
        emailAddress: "",
        phoneNumber: "",
        shopName: "",
        state: "",
        zipCode: "",
        password: "",
    });
    const [submitRequest, setSubmitRequest] = useState(false);
    useEffect(() => {
    }, []);
    const updateData = e => {
        setFormData({
            ...formdata,
            [e.target.name]: e.target.value
        })

    }
    const submitData = e => {
        setSubmitRequest(true);
        if (typeof formdata !== "undefined" && formdata.firstName !== "" &&
            formdata.address !== "" &&
            formdata.city !== "" &&
            formdata.shopName !== "" &&
            formdata.emailAddress !== "" &&
            formdata.phoneNumber !== "" &&
            formdata.state !== "" &&
            formdata.zipCode !== ""
        ) {
            dispatch(shopActions.createUser(formdata));

        }

        if (shopUser.success) {
            setFormData({
                firstName: "",
                address: "",
                city: "",
                emailAddress: "",
                phoneNumber: "",
                shopName: "",
                state: "",
                zipCode: "",
                password: "",
                websiteUrl: ""
            });
        }
    }
    return (
        <Box className="white-bg">
            <Container fixed className="py-60">
                <Box className="white-bg shopprofilelayout">

                    <Grid container spacing={[0, 0]} columnSpacing={{ xs: 0, sm: 3, md: 4 }} className="shopSigniconArea text-align-center">
                        <Grid item xs={6} sm={6} md={3}>
                            <Box className="shopIcon"><img src={ShopSignicon1} /></Box>
                            <h3 className='font-normal pb-10'>Create account</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Box className="shopIcon"> <img src={ShopSignicon2} /></Box>
                            <h3 className='font-normal pb-10'>Add Shop</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Box className="shopIcon"><img src={ShopSignicon3} /></Box>
                            <h3 className='font-normal pb-10'>Product Listing</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                            <Box className="shopIcon"> <img src={ShopSignicon4} /></Box>
                            <h3 className='font-normal pb-10'>Sale</h3>
                            <p>Lorem Ipsum has been the industry's standard dummy text ever since</p>
                        </Grid>
                    </Grid>

                    <Box className="text-center w-80 mx-auto"><h2 className='gColor font-extralarge'>Sell your Products on Shop Mart</h2>
                        <h2 className='gColor font-extralarge'>Get listed as a Merchant on the Shop Mart App and Website</h2>
                        <p className='pt-10'>Please give us a few details so that we can see if your store would be a good fit for the Shop Mart App at the moment. Note that this is an application to list on the Shop Mart app and doesn't guarantee listing in any form or manner. We will be getting in touch with you within 5 business days.</p>
                    </Box>

                    <Box className="shopprofileInner">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <label>Name</label>
                                <TextField className='w-100 form-control' placeholder="Robert H. Beadle" variant="outlined" name="firstName" value={formdata.firstName} onChange={updateData} />
                                {submitRequest && formdata.firstName === "" && <span className="error errorValidation">Name is required</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Company/Shop name</label>
                                <TextField className='w-100 form-control' placeholder="Robert H. Beadle" variant="outlined" name="shopName" value={formdata.shopName} onChange={updateData} />
                                {submitRequest && formdata.shopName === "" && <span className="error errorValidation">Shop name is required</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Email</label>
                                <TextField className='w-100 form-control' placeholder="RobertHBeadle@jourrapide.com" variant="outlined" value={formdata.emailAddress} name="emailAddress" onChange={updateData} />
                                {submitRequest && formdata.emailAddress === "" && <span className="error errorValidation">Email address is required</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Phone</label>
                                <TextField className='w-100 form-control' placeholder="510-554-4468" variant="outlined" name="phoneNumber" value={formdata.phoneNumber} onChange={updateData} />
                                {submitRequest && formdata.phoneNumber === "" && <span className="error errorValidation">Phone number is required</span>}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label>Website URL</label>
                                <TextField className='w-100 form-control' placeholder="https://www.google.com/" variant="outlined" name="websiteUrl" value={formdata.websiteUrl} onChange={updateData} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <label>Address</label>

                                <TextField className='w-100 form-control' placeholder="351 Harter Street" variant="outlined" name="address" value={formdata.address} onChange={updateData} />
                                {submitRequest && formdata.address === "" && <span className="error errorValidation">Address is required</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <label>Password</label>
                                <TextField className='w-100 form-control' placeholder="New password" variant="outlined" name="password" value={formdata.password} onChange={updateData} />
                                {submitRequest && formdata.password === "" && <span className="error errorValidation">Password is required</span>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4}>
                                <label>City</label>
                                <FormControl fullWidth>
                                    <Select className='form-control' name="city" value={formdata.city} vale="City 01" onChange={updateData}>
                                        <MenuItem value={0}>Sydney</MenuItem>
                                        <MenuItem value={1}>Melbourne</MenuItem>
                                        <MenuItem value={2}>Brisbane</MenuItem>
                                        <MenuItem value={3}>Perth</MenuItem>
                                        <MenuItem value={4}>Adelaide</MenuItem>
                                        <MenuItem value={5}>Gold Coast</MenuItem>
                                        <MenuItem value={6}>Canberra</MenuItem>
                                        <MenuItem value={7}>Newcastle</MenuItem>
                                        <MenuItem value={8}>Sunshine Coast</MenuItem>
                                        <MenuItem value={9}>Wollongong</MenuItem>
                                        <MenuItem value={10}>Hobart</MenuItem>
                                        <MenuItem value={11}>Geelong</MenuItem>
                                        <MenuItem value={12}>Townsville</MenuItem>
                                        <MenuItem value={13}>Cairns</MenuItem>
                                        <MenuItem value={14}>Darwin</MenuItem>
                                        <MenuItem value={15}>Toowoomba</MenuItem>
                                        <MenuItem value={16}>Ballarat</MenuItem>
                                        <MenuItem value={17}>Bendigo</MenuItem>
                                        <MenuItem value={18}>Albury-Wodonga</MenuItem>
                                        <MenuItem value={19}>Launceston</MenuItem>

                                    </Select>
                                    {submitRequest && formdata.city === "" && <span className="error errorValidation">City is required</span>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} lg={4}>
                                <label>State</label>
                                <FormControl fullWidth>
                                    <Select className='form-control' name="state" value={formdata.state} onChange={updateData}>
                                        <MenuItem value={0}>New South Wales</MenuItem>
                                        <MenuItem value={1}>Victoria</MenuItem>
                                        <MenuItem value={2}>Queensland</MenuItem>
                                        <MenuItem value={3}>Western Australia</MenuItem>
                                        <MenuItem value={4}>South Australia</MenuItem>
                                        <MenuItem value={5}>Tasmania</MenuItem>
                                        <MenuItem value={6}>Australian Capital Territory</MenuItem>
                                        <MenuItem value={7}>Northern Territory</MenuItem>

                                    </Select>
                                    {submitRequest && formdata.state === "" && <span className="error errorValidation">State is required</span>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} lg={4}>
                                <label>Zip Code</label>
                                <TextField className='w-100 form-control' placeholder="569852" variant="outlined" name="zipCode" value={formdata.zipCode} onChange={updateData} />
                                {submitRequest && formdata.zipCode === "" && <span className="error errorValidation">zipcode is required</span>}
                            </Grid>

                            <Grid item xs={12} className='text-align-right shopSignBtn'>
                                <Button className="button-radius btn gradients-bg border-none" onClick={submitData} >Sign in</Button>
                            </Grid>
                            <Grid item xs={12}>
                                {shopUser.error && <div className="error">{shopUser.error.message}</div>}
                                {shopUser.success && <div className="success">{shopUser.success}</div>}
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Container>
            <Footer />
        </Box>
    );
}