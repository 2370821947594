import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import { Sidebar } from "ShopDashboard/sidebar/Sidebar";
import Strawberry from "images/strawberry.png";
import Orange from "images/orange.png";
import Grapes from "images/grapes.png";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Footer } from "footer/Footer";
import "./neworder.scss";

const label = { inputProps: { "aria-label": "Switch demo" } };

const steps = [
  "Order Received",
  "Preparing",
  "Ready For Pick Up",
  "Completed Order",
];
const OrderSteps = {
  "New": "0",
  "Approved": "1",
  "Preparing": "2",
  "Ready": "3",
  "Completed": "4",
  "Cancelled": "5"
};

export { OrderDetail };

function OrderDetail({ orderRenderData }) {
  const { user: authUser } = useSelector((x) => x.auth);
  const dispatch = useDispatch();
  const [orderStatus, setOrderStatus] = React.useState(0);

  useEffect(() => {

    if (typeof orderRenderData != "undefined") {
      dispatch(shopActions.getOrderDetail(orderRenderData.id));
      setOrderStatus(OrderSteps[orderRenderData.orderStatus])
    }
  }, [orderRenderData]);
  const { getOrderDetail: OrderDetail } = useSelector((x) => x.shop);
  const [orderItems, setOrderItems] = React.useState([]);

  useEffect(() => {
    if (typeof OrderDetail != "undefined" && OrderDetail.data) {
      console.log(OrderDetail, ">>>>>>>>>>>>>>>>>>>>>>>>>>")
      setOrderItems(OrderDetail.data)
    }
  }, [OrderDetail]);

  const updateOrderStatus = (status) => {
    console.log({ status })
    dispatch(shopActions.updateOrderStatus({ id: orderRenderData.id, status: status }));
  }
  const { updateOrderStatus: statusUpdated } = useSelector((x) => x.shop);

  useEffect(() => {
    if (typeof statusUpdated != "undefined" && statusUpdated.data && !statusUpdated.data.message && !statusUpdated.error) {
      console.log(statusUpdated.data.orderStatus, ">---------->")
    //  dispatch(shopActions.getAllOrders());
      setOrderStatus(OrderSteps[statusUpdated.data.orderStatus])
    }
  }, [statusUpdated]);
  return (
    <Grid item xs={12} sm={12} lg={9} className="newOrderCol">
     <Box className="newOrderInfo">
      <Box className="light-blue-bg p-20 border-radius">
        <Box className="orderStepBar pt-10">
          <Box className="stepBar">
            {(orderStatus && orderStatus == 5) ?
               <Box className="cancelOrder"> Order Cancelled</Box>
              :
              <Stepper activeStep={orderStatus} alternativeLabel>
                {steps.map((label, i) => (
                  <Step key={label} onClick={() => updateOrderStatus(i + 1)} >
                    <StepLabel onClick={() => updateOrderStatus(i + 1)} >{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            }
          </Box>

          <Box className="d-flex my-30">
            {orderStatus == "0" &&
              <><Button
                onClick={() => updateOrderStatus(5)}
                className="btn outline-btn mr-15"
                variant="outlined"
              >
                Reject
              </Button>
                <Button
                  onClick={() => updateOrderStatus(2)}
                  className="btn primary-btn" variant="contained">
                  Accept Order
                </Button>
                <Button
                  className="btn primary-btn ml-auto"
                  variant="contained"
                >
                  Invoice{" "}
                  <FileDownloadOutlinedIcon className="downloadIcon" />
                </Button> </>
            }
          </Box>
        </Box>

        <Box className="d-flex pt-20 pt-xs-0">
          <h3 className="gColor font-large">Store Items 3</h3>
          <Box className="d-flex ml-auto">
            <span className="gColor font-small fw-700 d-flex">
              <Person2OutlinedIcon /> {orderRenderData.pickupTime}
            </span>{" "}
            <span className="gColor font-small fw-700 pl-15 d-flex">
              <CallOutlinedIcon /> {orderRenderData.pickupTime}
            </span>
          </Box>
        </Box>

        <Grid container className="newOrderHeader hiddenXS">
          <Grid item xs={12} sm={6} lg={7} className="newOrderCol">
            <span>Items</span>
          </Grid>
          <Grid item xs={12} sm={2} lg={1} className="newOrderCol text-center">
            <span>QTY.</span>
          </Grid>
          <Grid item xs={12} sm={2} lg={2} className="newOrderCol text-center">
            <span>Amount</span>
          </Grid>
          <Grid item xs={12} sm={2} lg={2} className="newOrderCol text-center">
            <span>Stock</span>
          </Grid>
        </Grid>
        {orderItems && Object.keys(orderItems).length > 0 && orderItems.message &&
          <Grid container className="newOrderRow"> {orderItems.message} </Grid>
        }
        {orderItems && Object.keys(orderItems).length > 0 && typeof orderItems.message == "undefined" &&
          orderItems.map(function (orderItem, i) {
            return <Grid container className="newOrderRow">
              <Grid item xs={12} sm={6} lg={7} className="newOrderCol">
                <Box className="productimgTxt">
                  <Box className="productimg">
                    <img src={Strawberry} />
                  </Box>
                  <Box className="productinfo">
                    <h3>{orderItem.itemName}</h3>
                    <span className="popupTxt">{orderItem.itemQuantity} box (180 - 200 gm)</span>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                lg={1}
                className="catCol text-center"
              > <span className="hiddenSM iPhoneTxt">QTY.</span>
                <span className="popupTxt addPricetxt">{orderItem.itemQuantity}</span>
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                lg={2}
                className="catCol text-center"
              ><span className="hiddenSM iPhoneTxt">Amount</span>
                <span className="popupTxt addPricetxt">$ {orderItem.itemPrice}</span>
              </Grid>
              <Grid
                item
                xs={4}
                sm={2}
                lg={2}
                className="catCol text-center"
              ><span className="hiddenSM iPhoneTxt">Stock</span>
                <span className="checkBtn">
                  <Switch {...label} defaultChecked />
                </span>
              </Grid>
            </Grid>
          })}
 </Box>

        <Box className="d-flex">
          <Box className="ml-auto invoice-txt-area">
            <h2>Invoice</h2>
            <Box className="d-flex">
              <span>Item total</span>
              <span className="ml-auto amount">$ {orderRenderData.orderTotal}</span>
            </Box>
            <Box className="d-flex">
              <span>To pay</span>
              <span className="ml-auto amount">$ {orderRenderData.orderTotal}</span>
            </Box>
          </Box>
        </Box>
      </Box>


    </Grid>
  );
}
