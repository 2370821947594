import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { history } from "_helpers"; 
import { fetchWrapper } from '_helpers';
 
// create slice

const name = 'customers';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const customerActions = { ...slice.actions, ...extraActions };
export const customerReducer = slice.reducer;

// implementation

function createInitialState() {
    return { 
        //initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('cust')),
        error: null
    } 
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/customers`;
    return { 
         getOrderList: getOrderList(),
       logout
    }; 
    
    function logout(state) {
        state.user = null;
        localStorage.removeItem('cust');
        history.navigate('/home');
    }
    function getOrderList() { 
        const user = localStorage.getItem('cust');
        if (!user) {
            return {};
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + JSON.parse(user).token }
        };
        return createAsyncThunk(
            `get_customer_orders`,
            async () => {
                const res = await fetch(`${baseUrl}/get_customer_orders`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
    }
  
}
 

function createExtraReducers() {
    return { 
         ...getOrderList() 
    };
 
    function getOrderList() {
        var { pending, fulfilled, rejected } = extraActions.getOrderList;
        return {
            [pending]: (state) => {
                state.getOrderList = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getOrderList = action.payload;
            },
            [rejected]: (state, action) => {
                state.getOrderList = { error: action.error };
            }
        };
    }

 
   
}
