import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar';
import { Footer } from 'footer/Footer';

import './subscription.scss';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


export { Subscription };

function Subscription() {
    const [selectedOption, setSelectedOption] = useState("monthly");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.id);
    };


    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box className="dashboardRightcontent subscriptionOuter">

                <Box className="pt-30 text-center">
                    <Box className="switch-wrapper text-center">
                        <input id="monthly" type="radio" name="switch" checked={selectedOption === "monthly"} onChange={handleOptionChange} />
                        <input id="yearly" type="radio" name="switch" checked={selectedOption === "yearly"} onChange={handleOptionChange} />
                        <label htmlFor="monthly">Monthly</label>
                        <label htmlFor="yearly">Yearly</label>
                        <span class="highlighter"></span>
                    </Box> 
                </Box>

                <Box className="table-wrapper mt-30">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>
                                    <div class="heading">Basic</div>
                                </TableCell>
                                <TableCell>
                                    <div class="heading">Standard</div>
                                </TableCell>
                                <TableCell>
                                    <div class="heading">Premium</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <div class="info text-left">
                                        <div className='font-medium bColor fw-700 pt-5'>Features</div>
                                        <span className='font-small fw-400'>See details Below</span>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div class="info">
                                        <div className={`price ${selectedOption === "monthly" ? "monthly" : "monthly hide"}`}>
                                            <div className="amount">$10 <span>PER MONTH</span></div>
                                        </div>

                                        <div className={`price ${selectedOption === "yearly" ? "yearly" : "yearly hide"}`}>
                                            <div className="amount">$7 <span>YEARLY</span></div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>

                                    <div class="info">
                                        <div className={`price ${selectedOption === "monthly" ? "monthly" : "monthly hide"}`}>
                                            <div className="amount">$22 <span>PER MONTH</span></div>
                                        </div>

                                        <div className={`price ${selectedOption === "yearly" ? "yearly" : "yearly hide"}`}>
                                            <div className="amount">$17 <span>YEARLY</span></div>
                                        </div>
                                    </div>
                                </TableCell>
                                <TableCell>

                                    <div class="info">
                                        <div className={`price ${selectedOption === "monthly" ? "monthly" : "monthly hide"}`}>
                                            <div className="amount">$35 <span>PER MONTH</span></div>
                                        </div>

                                        <div className={`price ${selectedOption === "yearly" ? "yearly" : "yearly hide"}`}>
                                            <div className="amount">$29 <span>YEARLY</span></div>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>

                            <TableRow>
                                <TableCell>Domain redirects</TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Lorem ipsum</TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell>Lorem ipsum</TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>Lorem ipsum</TableCell>
                                <TableCell>
                                    <CloseOutlinedIcon className='closeIconred' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell>Lorem ipsum</TableCell>
                                <TableCell>
                                    <CloseOutlinedIcon className='closeIconred' />
                                </TableCell>
                                <TableCell>
                                    <CloseOutlinedIcon className='closeIconred' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell>Lorem ipsum</TableCell>
                                <TableCell>
                                    <CloseOutlinedIcon className='closeIconred' />
                                </TableCell>
                                <TableCell>
                                    <CloseOutlinedIcon className='closeIconred' />
                                </TableCell>
                                <TableCell>
                                    <CheckOutlinedIcon className='checkIcongreen' />
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>
                                    <button type="button">Get started</button>
                                </TableCell>
                                <TableCell>
                                    <button type="button">Get started</button>
                                </TableCell>
                                <TableCell>
                                    <button type="button">Get started</button>
                                </TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>

                </Box>

                <Footer />

            </Box>
        </Box>
    );
}