import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
 
import { history, fetchWrapper } from '_helpers';

// create slice

const name = 'common';
const initialState = createInitialState();
const extraActions = createExtraActions();
const reducers = createReducers();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,reducers, extraReducers });

// exports

export const commonActions = { ...slice.actions, ...extraActions };
export const commonReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        users: {} 
    }
}
function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.customer = null;
        localStorage.removeItem('cust');
        history.navigate('/signin');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/common`;
    return { 
        globalCategories: globalCategories(),
        topRatedShops: topRatedShops(),
        getCategoriesByID:getCategoriesByID(),
        getProductsByID : getProductsByID(),
        registerCustomer : registerCustomer(),
        signin:signin(),
        placeOrder : placeOrder(),
        parentCategoryDetail : parentCategoryDetail(),
        shopDetail : shopDetail()
    };
 
    function globalCategories() {
        
        const requestOptions = {  method: 'GET' };
        return createAsyncThunk(
            `get_global_category`,
            async () => {
                const res = await fetch(`${baseUrl}/get_global_category`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function topRatedShops() {
        
        const requestOptions = {  method: 'GET' };
        return createAsyncThunk(
            `get_top_rated_shops`,
            async () => {
                const res = await fetch(`${baseUrl}/get_top_rated_shops`, requestOptions).then(
                (data) => data.json()
              )
              return res
                }

           
        );
        
    }
    function getCategoriesByID() { 
        return createAsyncThunk(
            `get_categories_by_id`,
            async (params) => {
                const res = await fetch(`${baseUrl}/get_categories_by_id`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
    function getProductsByID() { 
        return createAsyncThunk(
            `get_products_by_id`,
            async (params) => {
                const res = await fetch(`${baseUrl}/get_products_by_id`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
    function registerCustomer() { 
        return createAsyncThunk(
            `register`,
            async (params) => {
                const res = await fetch(`${baseUrl}/register`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
    function signin() {
        return createAsyncThunk(
            `${name}/signin`,
            async ({ emailAddress, password }) => await fetchWrapper.post(`${baseUrl}/login`, { emailAddress, password })
        );
    }
    function placeOrder() { 
        return createAsyncThunk(
            `place_order`,
            async (params) => {
                const res = await fetch(`${baseUrl}/place_order`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
    function parentCategoryDetail() { 
        return createAsyncThunk(
            `parent_category_detail`,
            async (params) => {
                const res = await fetch(`${baseUrl}/parent_category_detail`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
    function shopDetail() { 
        return createAsyncThunk(
            `shop_detail`,
            async (params) => {
                const res = await fetch(`${baseUrl}/shop_detail`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json'},
                    body:JSON.stringify(params) 
                }).then(
                (data) => data.json()
              )
              return res
                } 
        ); 
    }
}

function createExtraReducers() {
    return { 
        ...globalCategories(),
        ...topRatedShops(),
        ...getCategoriesByID(),
        ...getProductsByID(),
        ...registerCustomer(),
        ...signin(),
        ...placeOrder(),
        ...parentCategoryDetail(),
        ...shopDetail()
    };

    
    function globalCategories() {
        var { pending, fulfilled, rejected } = extraActions.globalCategories;
        return {
            [pending]: (state) => {
                state.globalCategories = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.globalCategories = action.payload;
            },
            [rejected]: (state, action) => {
                state.globalCategories = { error: action.error };
            }
        };
    }
    function topRatedShops() {
        var { pending, fulfilled, rejected } = extraActions.topRatedShops;
        return {
            [pending]: (state) => {
                state.topRatedShops = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.topRatedShops = action.payload;
            },
            [rejected]: (state, action) => {
                state.topRatedShops = { error: action.error };
            }
        };
    }
    function getCategoriesByID() {
        var { pending, fulfilled, rejected } = extraActions.getCategoriesByID;
        return {
            [pending]: (state) => {
                state.getCategoriesByID = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getCategoriesByID = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.getCategoriesByID = { error: action.error };
            }
        };
    }
    function getProductsByID() {
        var { pending, fulfilled, rejected } = extraActions.getProductsByID;
        return {
            [pending]: (state) => {
                state.getProductsByID = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.getProductsByID = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.getProductsByID = { error: action.error };
            }
        };
    }
    function registerCustomer() {
        var { pending, fulfilled, rejected } = extraActions.registerCustomer;
        return {
            [pending]: (state) => {
                state.registerCustomer = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.registerCustomer = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.registerCustomer = { error: action.error };
            }
        };
    }
    function signin() {
        var { pending, fulfilled, rejected } = extraActions.signin;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload;   
                localStorage.setItem('cust', JSON.stringify(user));
                state.user = user; 
                history.navigate("/"); 
            },
            [rejected]: (state, action) => {
                state.error = action.error;
            }
        };
    }
    function placeOrder() {
        var { pending, fulfilled, rejected } = extraActions.placeOrder;
        return {
            [pending]: (state) => {
                state.placeOrder = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.placeOrder = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.placeOrder = { error: action.error };
            }
        };
    }
    function parentCategoryDetail() {
        var { pending, fulfilled, rejected } = extraActions.parentCategoryDetail;
        return {
            [pending]: (state) => {
                state.parentCategoryDetail = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.parentCategoryDetail = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.parentCategoryDetail = { error: action.error };
            }
        };
    }
    function shopDetail() {
        var { pending, fulfilled, rejected } = extraActions.shopDetail;
        return {
            [pending]: (state) => {
                state.shopDetail = { loading: true };
            },
            [fulfilled]: (state, action) => {
                state.shopDetail = { data: action.payload };
            },
            [rejected]: (state, action) => {
                state.shopDetail = { error: action.error };
            }
        };
    }
}
