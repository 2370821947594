import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';
import { OrderDetail } from "./OrderDetail";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import { Sidebar } from "ShopDashboard/sidebar/Sidebar";
import Strawberry from "images/strawberry.png";
import Orange from "images/orange.png";
import Grapes from "images/grapes.png";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Footer } from "footer/Footer";
import "./neworder.scss";

const label = { inputProps: { "aria-label": "Switch demo" } };

const steps = [
  "Order Received",
  "Preparing",
  "Ready For Pick Up",
  "Completed Order",
];

export default NewOrder;

function NewOrder(params) {
  const { user: authUser } = useSelector((x) => x.auth);
  if (!authUser) {
    history.navigate('/signin');
  }
  const dispatch = useDispatch();

  const { getAllOrders: orderData } = useSelector((x) => x.shop);
  useEffect(() => {
    dispatch(shopActions.getAllOrders(params));
  }, [params.orderType]);
  const [orderList, setOrderList] = React.useState(10);

  useEffect(() => {
    if (typeof orderData != "undefined" && orderData.data && !orderData.data.message && !orderData.data.error) {
      setOrderList(orderData.data);
      setRenderDataObj(orderData.data[0])

    }
  }, [orderData]);
  const [renderDataObj, setRenderDataObj] = React.useState([]);

  const orderRender = (object, i) => {
    setRenderDataObj(object)
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box className="dashboardRightcontent">
        <Box className="filter-bg">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} lg={3}>
              <span className="search-bg">
                <IconButton type="button" sx={{ p: "0px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter order ID"
                  inputProps={{ "aria-label": "search anything..." }}
                />
              </span>
            </Grid>
          </Grid>
        </Box>

        <Box className="newOrderlistlayout">
          {orderData && orderData.data?.message &&
            <Grid container spacing={0} sx={{ pt: { xs: 1, sm: 3 } }} className="newOrderRow1">
              {orderData.data.message}
            </Grid>
          }
          {orderData && orderData?.loading &&
            <Grid container spacing={0} sx={{ pt: { xs: 1, sm: 3 } }} className="pt-0">
              <Box className="light-blue-bg p-20 border-radius w-100">
               <h3 className="font-medium fw-500">Loading...</h3>
               </Box>
            </Grid>
          }
          {orderData && orderData?.data && Object.keys(orderData.data).length == 0 &&
            <Grid container spacing={0} sx={{ pt: { xs: 1, sm: 3 } }} className="pt-0">
              <Box className="light-blue-bg p-20 border-radius w-100">
              <h3 className="font-medium fw-500">No Data Found...</h3>
              </Box>
            </Grid>
          }
          {orderData && orderData?.data && Object.keys(orderData.data).length > 0 &&
            <Grid container spacing={0} sx={{ pt: { xs: 1, sm: 3 } }} className="newOrderRow2">
              <Grid item xs={12} sm={12} lg={3} className="newOrderCol">
                <List className="newOrderlist-bg newOrderlist p-0">
                  <h2 className="font-extralarge gColor">Manage Order</h2>

                  {orderList && Object.keys(orderList).length > 0 &&
                    orderList.map(function (object, i) {

                      return <ListItem className={`${renderDataObj.id == object.id ? "active" : ""}`}>
                        <a onClick={() => orderRender(object, i)} href="javascript:void(0);">
                          <dd>
                            <h3 className="w-100 fw-700 font-medium">Order ID: {object.id}</h3>
                            <span className="userName w-100">
                              <Person2OutlinedIcon className="userIcon" /> {object.firstName} {object.lastName}
                            </span>
                            <span className="w-100">
                              <Box className="phoneNumber"><CallOutlinedIcon className="phIcon" /> {object.phoneNumber}</Box>                              
                              <Box className="itemInfo pt-5">
                              {object.itemCount} items for $ {object.orderTotal} <br />
                              {object.pickupTime}</Box>
                            </span>
                          </dd>
                          
                        </a>
                      </ListItem>
                    })
                  }

                </List>
              </Grid>
              {renderDataObj &&
                <OrderDetail orderRenderData={renderDataObj} />
              }
            </Grid>
          }
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
