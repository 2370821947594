import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { commonActions } from '_store';
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search"; 
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from "@mui/material/Menu";

import { Grid, MenuItem } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Badge from "@mui/material/Badge";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"; 
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CartContext from '../customer/pages/CartManagement/CartContext';
import "./header.scss"; 
import mainLogo from "images/shopmart-header-logo.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("xs")]: {
      width: "0ch",
      "&:focus": {
        width: "12ch",
      },
    },
    [theme.breakpoints.up("sm")]: {
      width: "0ch",
      "&:focus": {
        width: "25ch",
      },
    },
  },
}));

export { Header };
function Header() {
  const [userIn, setuserIn] = React.useState("");
  useEffect(() => {
    const userData = (localStorage.getItem('cust')) ? localStorage.getItem('cust') : (localStorage.getItem('user') ? localStorage.getItem('user') : "");
    setuserIn(userData);
  }, []);


  const dispatch = useDispatch();
  const logout = () => dispatch(commonActions.logout());
  const { cart, addToCart, removeFromCart, totalAmount, itemCount } = useContext(CartContext);
  const [state, setState] = React.useState({ right: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      className="addTocartBar"
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 450 }} >
      <Box className="CartHeader d-flex">
        <h3 className="mainHeading font-subheading">Your Cart</h3>

        <Button className="closeBtn ml-auto" onClick={toggleDrawer(anchor, false)}>
          <CloseOutlinedIcon />
        </Button>
      </Box>
      <List className="addCartProductList">
        {cart && Object.keys(cart).length > 0 &&
          cart.map(function (cartItem, i) {
            return <ListItem className="d-flex">

              <Grid container spacing={[0, 0]} columnSpacing={{ xs: 0, sm: 3, md: 3 }}>
                <Grid item xs={5} sm={3} lg={3}>
                  <Box className="cartImage">
                    <img src={cartItem.productImage} />
                  </Box>
                </Grid>

                <Grid item xs={7} sm={9} lg={9} className="cartInfo d-flex align-items-center">
                  <Box className="w-100">
                    <h2 className="font-normal fw-600 gColor">{cartItem.productTitle}</h2>
                    <p>Quantity</p>
                    <Box className="d-flex">

                      <p className="font-xxs gColor d-flex align-items">
                        {cartItem.productQuantity} <KeyboardArrowDownIcon /></p>
                      <span className="font-normal bColor pl-15 ml-auto">
                        $ <b>{cartItem.productPrice}</b>
                      </span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>



              {/* <Box className="qutPrice d-flex">
                <Box className="qutBox">
                  <button
                  onClick={() => removeFromCart(cartItem.id)} 
                  >
                    <RemoveOutlinedIcon />
                  </button>
                  <TextField
                    type="number"
                    value="1"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <button>
                    <AddOutlinedIcon />
                  </button>
                </Box> 
              </Box> */}

            </ListItem>
          })
        }
      </List>
      <Box className="CartFooter d-flex">
        <Box className="d-flex flex-wrap">
          <span className="cartItems fw-500">Price: ({itemCount} items)</span>
          <span className="w-100 pt-5 font-normal fw-700">${totalAmount}</span>
        </Box>
        <NavLink to="/checkout" className="ml-auto" onClick={toggleDrawer(anchor, false)}><Button className="button-radius gradients-bg btn">
          Checkout
        </Button></NavLink>
      </Box>
    </Box>
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorEl(null);
  };

  const [openLocation, setOpenLocation] = React.useState(false);
  const handleOpenLocation = () => setOpenLocation(true);
  const handleCloseLocation = () => setOpenLocation(false);

  return (
    <AppBar className="header-bg loginHeader">
      <Toolbar className="m-h-auto flex-wrap1">
        <Box className="logo-location d-flex w-100-xs">
          <Typography className="main-logo" noWrap component="div">
            <NavLink className="logo-txt" to="/">
              <img src={mainLogo} />
            </NavLink>
          </Typography>
          {/* <Box className="location-info d-flex cursorP ml-auto" onClick={handleOpenLocation}>
            <FmdGoodOutlinedIcon></FmdGoodOutlinedIcon>
            <span>Mohali, Punjab</span>
            <ExpandMoreOutlinedIcon></ExpandMoreOutlinedIcon>
          </Box> */}
          {/* <Box className="ml-auto header-rightsidebar d-flex">
           <Search className="search-bg">
            <SearchIconWrapper>
              <SearchIcon className="svg-icon" />
            </SearchIconWrapper>
            <StyledInputBase
              className="search-input"
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <span className="cartIcon hiddenSM cartIconMobile ml-auto" onClick={toggleDrawer(anchor, true)}>
                <Badge badgeContent={5} className="carbadge">
                  <ShoppingCartOutlinedIcon
                    className="svg-icon"></ShoppingCartOutlinedIcon>
                </Badge>
              </span>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
          </Box> */}
        </Box>
        <Box className="ml-auto header-rightsidebar d-flex">
          <Search className="search-bg">
            <SearchIconWrapper>
              <SearchIcon className="svg-icon SearchIcon" />
            </SearchIconWrapper>
            <StyledInputBase
              className="search-input"
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
            />
          </Search>

          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <span className="cartIcon ml-auto" onClick={toggleDrawer(anchor, true)}>
                <Badge badgeContent={itemCount} className="carbadge">
                  <ShoppingCartOutlinedIcon
                    className="svg-icon"
                  ></ShoppingCartOutlinedIcon>
                </Badge>
              </span>

              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} >
                {list(anchor)}
              </Drawer>

            </React.Fragment>
          ))}
          {!userIn &&
            <>
              {/* <NavLink to="/shopsignup">
                <Button
                  className="button-radius mx-10 btn gradients-bg border-none"
                  variant="outlined"
                >
                  Business Account
                </Button>
              </NavLink>   */}
              <NavLink to="/signin">
                <Button
                  className="ml-15 button-radius btn gradients-bg border-none"
                  variant="contained">
                  Sign In
                </Button>
              </NavLink>
            </>}
          {userIn &&
            <> <span
              className="ProfileMenuIcon d-flex ml-15"
              aria-controls={openProfile ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openProfile ? "true" : undefined}
              onClick={handleClickProfile}>
              <span className="userName">SS</span>
              <KeyboardArrowDownOutlinedIcon className="arrowdown" />
            </span>
              <Menu
                className="profileSubmenu"
                anchorEl={anchorEl}
                open={openProfile}
                
              >
                <MenuItem><NavLink to='/orderlist'>Order List</NavLink></MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </>
          }

        </Box>
      </Toolbar>
      <Modal
        className="modalPopupTxt"
        open={openLocation}
        onClose={handleCloseLocation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Button className="closepopup" onClick={handleCloseLocation}>
              <CloseIcon />
            </Button>
            <Box>
              <h2 className="mainHeading font-subheading">Add your location</h2>
              <Paper component="form" className="d-flex mt-15 mb-15 border-1 boxShadowNone">
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <SearchIcon className="pColor" />
                </IconButton>
                <InputBase placeholder="Search for area, location name" className="w-100 pr-10 pColor" />
              </Paper>
              <p className="d-flex"><MyLocationOutlinedIcon className="mr-10" /> Use current location</p>
            </Box>
          </Box>
        </Box>
      </Modal>
    </AppBar>
  );
}
