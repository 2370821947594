import * as React from 'react';
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { authActions, store } from '_store';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar';
import { Footer } from 'footer/Footer'; 
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl'; 
import FormControlLabel from '@mui/material/FormControlLabel';

import Switch from '@mui/material/Switch';
import './profile.scss';
 
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker'; 
import shopLogodemo from "images/shopLogoDemo.png";
import demoUserImg from "images/demo-userimg.jpg";
import demoShopImg from "images/top-rated-shops-bg.png";
import { NavLink } from 'react-router-dom';

export { EditProfile };

function EditProfile(props) {
    const [profileObj, setProfileObj] = useState();
    const [updatedMessage, setUpdatedMessage] = useState();
  const { user: authUser } = useSelector((x) => x.auth); 
  if (!authUser) { 
    history.navigate('/signin');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.getProfileData()); 
  }, []);

  const { getProfileData: userData } = useSelector((x) => x.auth);
   
 
    const [valueAM, setValueAM] = React.useState();
    const handleChangeAM = (newValueAM) => {
        setValueAM(newValueAM);
    };

    const [valuePM, setValuePM] = React.useState();
    const handleChangePM = (newValuePM) => {
        setValuePM(newValuePM);
    };
const submitData = ()=>{
    setUpdatedMessage("Profile saved successfully.");
}
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box className="dashboardRightcontent profleInfo">  
            {userData && 

                <Box className="profilelightBG">
                <Box className="profilelayout">

                <Box>
                <h3 className='font-small fw-700 bColor'>Shop Photo</h3>
                <Box className="shopBannerImg">
                 <img src={demoShopImg} className='shopImg w-100' />

                 <Box className="d-flex"><NavLink className="editshopBanner" ><ModeEditOutlineOutlinedIcon/></NavLink></Box>
                </Box>
               </Box>

               <Grid container spacing={2}>

               <Grid item xs={12} sm={6} lg={6}>
                 <h3 className='font-small fw-700 bColor pb-10'>Shop Logo</h3>
                 <Box className="shopLogoImg mb-20">
                  <span className='shopLogo'> <img src={shopLogodemo}  /></span>
                  <Button component="label" className='gradients-bg btn'>Choose Image
                 <input type="file" hidden /></Button> 
                </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
               <h3 className='font-small fw-700 bColor pb-10'>User Profile</h3>
               <Box className="shopLogoImg mb-20">
                  <span className='shopUserImg'> <img src={demoUserImg}  /></span>
                  <Box><Button component="label" className='gradients-bg btn'>Choose Image
                 <input type="file" hidden /></Button> <br/>
                 <Button className='outline-btn btn mt-10'>Delete</Button> </Box>
                </Box>
                </Grid>

               </Grid>

                     <Grid container className='editProfile pt-40'>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Name</label>
                            <TextField className='w-100 form-control' value={userData.firstName}variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Email</label>
                            <TextField className='w-100 form-control' value={userData.emailAddress}variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Phone</label>
                            <TextField className='w-100 form-control' value="510-554-4468" variant="outlined" />
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Address</label>

                            <TextField className='w-100 form-control' value="351 Harter Street" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>City</label>
                            <FormControl fullWidth>
                                <Select className='form-control' vale="City 01">
                                    <MenuItem value={0}>City 01</MenuItem>
                                    <MenuItem value={1}>City 02</MenuItem>
                                    <MenuItem value={2}>City 03</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>State</label>
                            <FormControl fullWidth>
                                <Select className='form-control' vale="State 01">
                                    <MenuItem value={0}>State 01</MenuItem>
                                    <MenuItem value={1}>State 02</MenuItem>
                                    <MenuItem value={2}>State 03</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Zip Code</label>
                            <TextField className='w-100 form-control' value="569852" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Account Holder Name</label>
                            <TextField className='w-100 form-control' value={userData.firstName}variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Account Number</label>
                            <TextField className='w-100 form-control' value="510-554-4468" variant="outlined" />
                        </Grid>

                        <Grid item xs={12} sm={4} lg={4}>
                            <label>IFSC Code</label>
                            <TextField className='w-100 form-control' value="510-554-4468" variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={4} lg={4}>
                            <label>Shop Open <span className='closeTimingtxt'>Close Timing</span></label>
                             <Box className="d-flex">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker className='form-control timefeild' value={valueAM} onChange={handleChangeAM}
                                    renderInput={(params) => <TextField {...params} />} />
                                    <span className='px-10'> </span>
                            <TimePicker className='form-control timefeild' value={valuePM} onChange={handleChangePM} 
                                    renderInput={(params) => <TextField {...params} />} />
                            </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item  xs={12} sm={4} lg={4}> 
                        <Box className="d-flex sundayClose">
                            <label className='pr-10 pb-0'>Sunday Close</label>
                            <FormControlLabel className="form-control switch-btn" control={<Switch defaultChecked />} />
                            </Box>
                           </Grid>

                           <Grid item xs={12} className='text-align-right'>
                            <Button className="gradients-bg btn btn-h-40 mw-150" onClick={()=> submitData()}>Save</Button>
                        </Grid>
                        <Grid item xs={12}>
                            {updatedMessage}
                        </Grid>
                        
                    </Grid>
                </Box>
</Box>


}
                <Footer />
            </Box>
        </Box>
    );
}