import React, { useState, useReducer } from "react";

export const UserReducer = (state, action) => {
    switch(action.type) {
      case 'changeName':
        return {action}
      case 'changeColor':
        return {...state, color: action.color}
      case 'changeQuest':
        return {...state, quest: action.quest}
    }
  } 