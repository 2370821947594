import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, authActions, store } from '_store';
import Box from "@mui/material/Box"; 
import IconButton from "@mui/material/IconButton"; 
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Sidebar } from "ShopDashboard/sidebar/Sidebar";
import { Footer } from "footer/Footer";
import "./dashboardadmin.scss";
import demoVector from "images/demoVector.png";
import demoShopimg from "images/demoShopimg.png";
import SlideToggle from "react-slide-toggle";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

export { Dashboardadmin };

function Dashboardadmin() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth); 
  if (!authUser) { 
    history.navigate('/signin');
  }
  const [thisweek, setthisweek] = React.useState("");

  const handleChange = (event) => {
    setthisweek(event.target.value);
  };
  const [filter, setfilter] = React.useState("");
  const handleChangefilter = (event) => {
    setfilter(event.target.value);
  };

  const [toggleEvent, setToggleEvent] = React.useState(0);

  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };
  const [categoryListShop, setCategoryListShop] = useState([]);
  const [productListShop, setProductListShop] = useState([]);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCatID, setSelectedCatId] = useState("");
  const [userData, setUserData] = useState([]);
 
  
  const { shopCategories: shopCategoryList } = useSelector((x) => x.shop);
  const { ShopProducts: ProductList } = useSelector((x) => x.shop); 

  const loadProducts = (catObj) => {
    setSelectedCat(catObj.categoryName);
    setSelectedCatId(catObj.id);
    dispatch(shopActions.loadProductsByShopCategories(catObj.id));
  }

  useEffect(() => { 
    dispatch(authActions.getProfileData());
     dispatch(shopActions.shopCategories());
  }, []);

  const { getProfileData: getUserData } = useSelector((x) => x.auth);

  useEffect(() => {
    if (shopCategoryList && !shopCategoryList.loading) {
      setCategoryListShop(shopCategoryList.data)
    }
    if (ProductList && !ProductList.loading) { 
      setProductListShop(ProductList.data)
    }
  }, [shopCategoryList, ProductList]);

  useEffect(() => {
    if (typeof categoryListShop != "undefined" && typeof categoryListShop[0] != "undefined") {
      loadProducts(categoryListShop[0].sub[0])
    }
  }, [categoryListShop]);
  useEffect(() => {
    if (typeof getUserData != "undefined" && typeof getUserData[0] != "undefined") { 
       setUserData(getUserData[0])
    }
  }, [getUserData]);
  
  const DashboardleftLink = (
    <List>
      {typeof categoryListShop != "undefined" && categoryListShop && categoryListShop.map(function (object, i) {
        if (object.sub) {
          return object.sub.map(function (subObject, j) {
            return <>
              <ListItem className={(selectedCatID == subObject.id ? 'active' : '')} >
                <button onClick={() => { loadProducts(subObject) }} className="list-btn">{subObject.categoryName}</button>
              </ListItem>
            </>;
          })

        }



      })}
    </List>
  )


  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />

      <Box className="dashboardRightcontent">
        <Box className="filter-bg">
          <Grid container columnSpacing={{ xs: 0, sm: 2 }} rowSpacing={{ xs: 2, sm: 0 }}>
           
            <Grid item xs={12} sm={6} lg={6} className="ml-auto d-flex">
               
              <FormControl fullWidth className="customselect custom-filter ml-auto">
                <Select
                  className="form-control"
                  value={filter}
                  onChange={handleChangefilter}
                  displayEmpty
                  inputProps={{ "aria-label": "Without abel" }}
                >
                  <MenuItem value="">This Week</MenuItem> 
                  <MenuItem value={1}>Last Week</MenuItem>
                  <MenuItem value={2}>Last Month</MenuItem>
                  <MenuItem value={2}>This Month</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid> 
        </Box>

        <Grid
          container
          className="custom-card-layout"
          sx={{ mt: 0 }}
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={3}>
            <Box className="card-body">
              <Grid container>
                <Grid item xs={12} sm={6} lg={7}>
                  <h2>Total Orders</h2>
                  <h3>8,520</h3>
                </Grid>
                <IconButton className="mui-icon ShoppingBag" size="large">
                  <ShoppingBagOutlinedIcon />
                </IconButton>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Box className="card-body">
              <Grid container>
                <Grid item xs={12} sm={6} lg={7}>
                  <h2>Total Income</h2>
                  <h3>8,020</h3>
                </Grid>
                <IconButton className="mui-icon AttachMoney" size="large">
                  <AttachMoneyOutlinedIcon />
                </IconButton>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Box className="card-body">
              <Grid container>
                <Grid item xs={12} sm={6} lg={7}>
                  <h2>Total Customer</h2>
                  <h3>5020</h3>
                </Grid>
                <IconButton className="mui-icon Diversity2O" size="large">
                  <Diversity2OutlinedIcon />
                </IconButton>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Box className="card-body">
              <Grid container>
                <Grid item xs={12} sm={6} lg={7}>
                  <h2>Total Products</h2>
                  <h3>1220</h3>
                </Grid>
                <IconButton className="mui-icon Article" size="large">
                  <ArticleOutlinedIcon />
                </IconButton>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="shopBGbanner" sx={{ mt: 4, py: { xs: 2, sm: 4, }, }}>
          <Container fixed className="container-fluid">
            <Grid container columnSpacing={{ xs: 2, sm: 2 }} rowSpacing={{ xs: 2, sm: 0 }}>
             <Grid className="breadcrumbLinks" item xs={12} sm={12} lg={12}>
             
             <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" to="/home">
              Home
            </Link>
            <Link
              underline="hover"
              color="text.primary"
              to=""
              aria-current="page"
            >
              
            </Link>
          </Breadcrumbs>
  </Grid>
              <Grid className="shopImgOuer" item xs={12} sm={3} lg={2}> 
                 <span className="shopImgInner"> <img src={demoShopimg} className="w-100" /> </span>

                  <p className="d-sm-none gColor d-flex flex-xs-wrap addressInfo pl-10">
                  <span className="address w-100 pl-5 pb-5">Santacruz East</span>
                    <span className="d-flex SvgIcon">
                      <FmdGoodOutlinedIcon /> 3.0 km
                    </span>
                    <span className="d-flex pl-15 SvgIcon">
                      <AccessTimeOutlinedIcon /> 25 mins
                    </span>
                  </p>
              </Grid>

              <Grid item xs={9} sm={9} lg={10}>
                <h2 className="mainHeading gColor font-subheading">
                  {userData &&
                  <>
                  {userData.shop_name}
                  </>
                }
                </h2>
                

                <p className="gColor d-flex addressInfo d-xs-none">
                <span className="address pr-25">Santacruz East</span>
                    <span className="d-flex SvgIcon">
                      <FmdGoodOutlinedIcon /> 3.0 km
                    </span>
                    <span className="d-flex pl-15 SvgIcon">
                      <AccessTimeOutlinedIcon /> 25 mins
                    </span>
                  </p>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Box className="productshowList" sx={{ mt: { xs: 3, sm: 5 } }}>
          <Container fixed className="container-fluid" sx={{ px: { xs: '0!important', }, }}>
          <Grid container columnSpacing={{ xs: 2, sm: 2 }} rowSpacing={{ xs: 2, sm: 0 }}>
              <Grid item xs={12} sm={4} lg={3} className="productshowList-left">
                <Box className="hiddenSM mb-10">
                  <Box sx={{ p: 2 }} className="gradients-bg border-radius">
                    <span onClick={handleToggleShow} className="subMenuArrow d-flex">
                      Menu <MenuIcon className="ml-auto" />
                    </span>
                  </Box>
                  <SlideToggle toggleEvent={toggleEvent} collapsed>
                    {({ setCollapsibleElement }) => (
                      <div className="submenuContent w-100" ref={setCollapsibleElement}>
                        {DashboardleftLink}
                      </div>
                    )}
                  </SlideToggle>
                </Box>

                <Box className="hiddenXS">
                  <Box className="DashboardLinkBG">
                  <Box sx={{ p: 2 }} className="gradients-bg border-radius">
                    <span className="fw-700 font-normal">
                    Category's
                    </span>
                  </Box>
                  {DashboardleftLink}
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={8}
                lg={9}
                className="productshowList-right"
              >
                <h3 className="font-subheading">{selectedCat}</h3>

                <Grid container className="productRow">

                  {productListShop && Object.keys(productListShop).length === 0 &&
                    <Grid container className="CatlistRow">
                      <Grid item xs={12} sm={12} lg={12} className="catCol">
                        <Box className="productimgTxt">
                          <p className="txtnotfound">Product not found.</p>
                        </Box></Grid></Grid>
                  }
                  {typeof productListShop != "undefined" && productListShop && productListShop.map(function (object, i) {
                    return <>
                      <Grid xs={12} sm={6} lg={3} className="productCol">
                        <Box className="prodctImg">
                          <img src={object.productImage} />
                        </Box>
                        <h4>{object.productTitle}</h4>
                        <p>{object.productDescription}</p>
                        <Box className="d-flex count-txt">
                          <span>{object.productQuantity}</span>
                          <span>{object.productPrice}</span>
                        </Box>
                        <Button
                          className="button-radius addTOcartBtn mr-10"
                          variant="contained"
                        >
                          <AddIcon /> ADD
                        </Button>
                      </Grid>

                    </>

                  })}


                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
