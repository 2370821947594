import * as React from "react";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { history, fetchWrapper } from '_helpers';
import { shopActions, store } from '_store';

import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Sidebar } from "ShopDashboard/sidebar/Sidebar";
import "./menu.scss";
import { Button, Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import { NavLink, Navigate } from "react-router-dom";
import { Footer } from "footer/Footer";
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const label = { inputProps: { "aria-label": "Checkbox demo" } };




export { Menupage };

function Menupage() {

  const [openAddCategories, setOpenAddCategories] = React.useState(false);
  const handleOpenAddCategories = () => setOpenAddCategories(true);
  const handleCloseAddCategories = () => setOpenAddCategories(false);

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [submitted, setSubmittedStatus] = useState(false);

  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { globalCategories: AllCategories } = useSelector((x) => x.shop);
  const { saveCategories: IsSaveCategories } = useSelector((x) => x.shop);
  if (!authUser) {
    // not logged in so redirect to SignIn page with the return url
    history.navigate('/signin');
  }
  useEffect(() => {
    dispatch(shopActions.globalCategories());
  }, []);
  useEffect(() => {
    if (AllCategories && !AllCategories.loading) {
      setCategoryList(AllCategories)
    }

  }, [AllCategories]);

  useEffect(() => {
    if (typeof IsSaveCategories != "undefined" && IsSaveCategories.message) {
      history.navigate('/menucategories');
    }

  }, [IsSaveCategories]);

  const handleSendSelection = (e, i) => {
    // categoryList 

    var tempProps = JSON.parse(JSON.stringify(categoryList));
    tempProps[i].checked = e.target.checked;
    Object.preventExtensions(tempProps);
    setCategoryList(tempProps);

    if (e.target.checked) {
      setSelectedCategories([
        ...selectedCategories,
        e.target.value,
      ]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((item) => item !== e.target.value),
      );
    }
  }
  const SubmitSelection = (e) => {
    setSubmittedStatus(true)
    if (selectedCategories.length > 0) {
      dispatch(shopActions.saveCategories(selectedCategories));

    }
  }
  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box className="dashboardRightcontent">
        <Box className="filter-bg">
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} lg={3}>
              <span className="search-bg">
                <IconButton type="button" sx={{ p: "0px" }} aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter the product name..."
                  inputProps={{ "aria-label": "Enter the product name..." }}
                />
              </span>
            </Grid>

            <Grid item xs={12} sm={6} lg={3} className="text-right d-flex ml-auto">
            <Button onClick={handleOpenAddCategories} className="btn outline-btn ml-auto"><AddOutlinedIcon className="mr-5"/> Add New Categories</Button>
            </Grid>

          </Grid>
        </Box>

        <Box className="menucatlayout">
          <Grid container spacing={0} pt={3} className="menuRow">
            {categoryList && Object.keys(categoryList).length > 0 &&
              categoryList.map(function (object, i) {
                return <Grid item xs={12} sm={6} lg={3} className="menuCol">
                  <FormControlLabel key={object.id}
                    control={<Checkbox value={object.id} checked={object.checked} onClick={(event) => handleSendSelection(event, i)} />}
                    icon={<CheckCircleIcon />}
                    checkedIcon={<CheckCircleIcon />}
                    label={object.categoryName}
                    className="CategoriesCheckbox"
                  />
                </Grid>;
              })}



          </Grid>
          <Box className="d-flex ">
            {submitted && selectedCategories.length === 0 &&
              <>Please select any Category</>
            }
          </Box>
          <Box className="d-flex select-cat">
            <h4 className="mr-auto">{selectedCategories.length} Categories Selected</h4>
            {/* <NavLink to="/menucategories"> */}
            <Button
              className="gradients-bg btn"
              variant="contained"
              size="large"
              onClick={SubmitSelection}
            >
              Next
            </Button>
            {/* </NavLink> */}

          </Box>
        </Box>

       
        <Footer />
      </Box>

      <Modal className="modalPopupTxt modalAddQuantity" open={openAddCategories}
        onClose={handleCloseAddCategories} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Box className="modalHeading">
              <Button className="closepopup" onClick={handleCloseAddCategories}><CloseIcon /></Button>
              <h2 className="gColor font-subheading text-center" id="modal-modal-title">Request Categories.</h2>
            </Box> 
            <FormControl fullWidth className='pt-40'>
              <label>Enter the name of the request category.</label>
              <TextField className='form-control mt-10' hiddenLabel id="filled-hidden-label-normal" placeholder="Category Name Type here...." />
            </FormControl>
            <Button className='btn gradients-bg btn-h-40 w-100 mt-15' variant='contained'>Send request </Button>
            <p className='font-small pt-10 pb-10'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type ...</p>
          

            {/* <Box className="modalHeading">
              <Button className="closepopup" onClick={handleCloseAddCategories}><CloseIcon /></Button> 
              <h2 className="gColor font-subheading text-center" id="modal-modal-title">Request Categories.</h2>
            </Box> 
            
            <Box className="text-center pt-40 pb-20">
            <CheckCircleOutlinedIcon className="font-70 pColor" />
            </Box>
            <h2 className="gColor font-subheading text-center">Your Request has been Submitted</h2>
            <p className='font-small pt-10 pb-10 text-center'>Lorem Ipsum has been the industry's standard</p>
            <Button className='btn gradients-bg btn-h-40 w-100 mt-15 mb-10' variant='contained'>Home</Button>  */} 
          </Box> 
        </Box> 
      </Modal>



    </Box>
  );
}
