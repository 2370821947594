import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { commonActions, authActions, store } from '_store';
import { history, fetchWrapper } from '_helpers';
import { Box } from "@mui/system";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import demoVector from "images/demoVector.png";
import demoImg from "images/demo-img.png";
import StoreImg from "images/storeImg.png";
import Container from "@mui/material/Container";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import SlideToggle from "react-slide-toggle";
import MenuIcon from "@mui/icons-material/Menu";
import CartContext from '../CartManagement/CartContext';
import { BrowserRouter as Router, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import "./store.scss";

export { StorePage };

function StorePage() {
  const { shopid } = useParams();
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const [shopInfo, setShopInfo] = useState([]);
  const [categoryInfo, setCategoryInfo] = useState([]);
  const { getCategoriesByID: categoryList } = useSelector((x) => x.common);
  const { getProductsByID: getProductList } = useSelector((x) => x.common);
  const [sidebarCatList, setSidebarCatList] = useState([]);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (location && location.state && location.state.category && location.state.shop) {

      window.scrollTo(0, 0)
      //  setShopInfo(location.state.shop)
      setCategoryInfo(location.state.category)
      const catId = { catId: location.state.category.id };
      dispatch(commonActions.getCategoriesByID(catId));

    } else {
      const catId = { catId: 0 };
      dispatch(commonActions.getCategoriesByID(catId));

    }
  }, [location.state]);
  useEffect(() => {
    if (shopid && shopid != "") {
      const shopReq = { shopId: shopid };
      dispatch(commonActions.shopDetail(shopReq));
    }
  }, [shopid]);
  const { shopDetail: shopDetails } = useSelector((x) => x.common);
  useEffect(() => {

    if (shopDetails && shopDetails.data) {
      setShopInfo(shopDetails.data[0]);
      dispatch(commonActions.getProductsByID({ shopId: shopDetails.data[0].id, catId: "" }));

    }
  }, [shopDetails]);

  useEffect(() => {
    //if (typeof categoryList.data != "undefined" && categoryList.data && !categoryList.loading) {
    setSidebarCatList(categoryList)
    // }
  }, [categoryList]);

  useEffect(() => {
    if (getProductList && typeof getProductList.data != "undefined" && !getProductList.loading) {
      setProductList(getProductList)
    }
  }, [getProductList]);
  const renderFilterProducts = (catId) => {
    dispatch(commonActions.getProductsByID({ shopId: shopInfo.id, catId: catId }));

  }
  const [openAddCart, setOpenAddCart] = React.useState(false);
  const [currentProductItem, setCurrentProductItem] = React.useState([]);
  const handleOpenAddCart = (productItem) => {
    setOpenAddCart(true);
    setCurrentProductItem(productItem);
  }
  const handleCloseAddCart = () => {
    setOpenAddCart(false);
    setCurrentProductItem([]);
  }

  const [toggleEvent, setToggleEvent] = React.useState(0);
  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };
  // const [cartProducts, setCartProducts] = React.useState([]);
  const addProductToCart = (product) => {
    setOpenAddCart(false);
    setCurrentProductItem([]);
    addToCart(product)
  }
  const storeleftLink = (
    <List>
      <ListItem>
        <button className="catHeading gradients-bg" onClick={() => renderFilterProducts("")}>{categoryInfo.categoryName}</button>
      </ListItem>
      {sidebarCatList?.loading &&
        <>Loading.....</>
      }
      {sidebarCatList.error &&
        <>{sidebarCatList.error.message}</>
      }
      {sidebarCatList.data && sidebarCatList.data.message &&
        <Box className="errorMessage">{sidebarCatList.data.message}</Box>
      }
      {sidebarCatList.data && !sidebarCatList.data.message && Object.keys(sidebarCatList.data).length > 0 &&
        sidebarCatList.data.map(function (object, i) {
          return <ListItem>
            <button className="list-btn" onClick={() => renderFilterProducts(object.id)} >{object.categoryName}</button>
          </ListItem>
        })
      }
    </List>
  )

  return (
    <Box>
      <Box className="storeBG py-30 StoreInnerPageHeader">
        <Box className="container">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" to="/">
              {categoryInfo.categoryName}
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to=""
              aria-current="page"
            >
              {shopInfo.title}
            </NavLink>
          </Breadcrumbs>

          <Box className="d-flex pt-20">
            <Box className="storeImg">
              <img src={StoreImg} />
            </Box>

            <Box className="pl-20">
              <h2 className="mainHeading font-subheading">{shopInfo.title}</h2>
              <p className="pt-5">{categoryInfo.categoryName}</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="productshowList pb-50">
        <Box className="storeListSearch">
          <Container fixed className="container">
            <Paper component="form" className="d-flex mt-0 mb-20 border-none boxShadowNone" sx={{ my: { xs: '0!important' } }}>
              <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon className="pColor" />
              </IconButton>
              <InputBase placeholder={`Search in ${shopInfo.title}`} className="w-100 pr-10 searchTxt" />

            </Paper>
          </Container>
        </Box>
        <Container fixed className="container">


          <Grid container className="" spacing={0}>


            <Grid item xs={12} sm={4} lg={3} className="StoreCatListleft">
              <Box className="hiddenSM mb-20">
                <Box sx={{ p: 2 }} className="blue-bg border-radius">
                  <span onClick={handleToggleShow} className="subMenuArrow d-flex">
                    Menu <MenuIcon className="ml-auto" />
                  </span>
                </Box>
                <SlideToggle toggleEvent={toggleEvent} collapsed>
                  {({ setCollapsibleElement }) => (
                    <div className="submenuContent w-100" ref={setCollapsibleElement}>
                      {storeleftLink}
                    </div>
                  )}
                </SlideToggle>
              </Box>

              <Box className="hiddenXS">
                {storeleftLink}
              </Box>

            </Grid>

            <Grid item xs={12} sm={8} lg={9} className="StoreCatListright">

              <h3 className="font-subheading">{categoryInfo.categoryName}</h3>

              <Grid container spacing={[3, 4]} columnSpacing={{ xs: 0, sm: 3, md: 4 }} className="StoreCatRow mt-0">
                {productList?.loading &&
                  <><Grid item xs={12} sm={12} lg={12} className="StoreCatCol">
                    Loading.....
                  </Grid>
                  </>
                }
                {productList.error &&
                  <>
                    <Grid item xs={12} sm={12} lg={12} className="StoreCatCol">
                      {productList.error.message}
                    </Grid>
                  </>
                }
                {productList.data && productList.data.message &&
                  <Grid item xs={12} sm={12} lg={12} className="StoreCatCol">
                    <Box className="errorMessage">{productList.data.message}</Box>
                  </Grid>
                }
                {productList.data && Object.keys(productList.data).length == 0 &&
                  <>
                    <Grid item xs={12} sm={12} lg={12} className="StoreCatCol">
                      No product found.
                    </Grid>
                  </>
                }

                {productList.data && !productList.data.message && Object.keys(productList.data).length > 0 &&
                  productList.data.map(function (object, i) {
                    return <Grid item xs={12} sm={6} lg={3} className="StoreCatCol">
                      <Box className="prodctImg text-center">
                        <img src={object.productImage} />
                      </Box>
                      <h4>{object.productTitle}</h4>
                      <p>{object.productDescription}</p>
                      <Box className="d-flex count-txt mt-15 mb-15">
                        <span className="fw-500">${object.productPrice}</span>
                        <span className="ml-auto pColor d-flex align-items">{object.productQuantity} <KeyboardArrowDownIcon /></span>
                      </Box>
                      <Button
                        onClick={() => { handleOpenAddCart(object) }}
                        className="button-radius border-btn white-btn btn mr-10"
                        variant="contained"
                      >
                        <AddIcon /> ADD
                      </Button>
                    </Grid>
                  })}


              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Modal
        className="modalPopupTxt"
        open={openAddCart}
        onClose={handleCloseAddCart}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">

            {!currentProductItem || Object.keys(currentProductItem).length == 0 &&
              <>An Error occured, please try again.</>
            }
            {currentProductItem && Object.keys(currentProductItem).length > 0 &&
              <>
                <Box className="ModalHeader">
                  <h2 className="font-subheading gColor" id="modal-modal-title">
                    Choose quantity
                  </h2>
                  <p> {currentProductItem.productTitle}</p>
                  <Button className="closepopup" onClick={handleCloseAddCart}>
                    <CloseIcon />
                  </Button>
                </Box>
                <Grid container spacing={[3, 4]} columnSpacing={{ xs: 0, sm: 3, md: 4 }} className="mt-0">
                  <Grid item xs={6} sm={6} lg={6} className="ModalImageCol">
                    <Box className="prodctImg text-center">
                    <img src={currentProductItem.productImage} /> 
                    </Box>

                  </Grid>
                  <Grid item xs={6} sm={6} lg={6} className="ModalInfoCol">
                    <h3 className="font-medium gColor fw-500" id="modal-modal-title">
                      {currentProductItem.productTitle}
                    </h3>
                    <p className="pb-30"> {currentProductItem.productDescription}</p>

                                  <RadioGroup
                      className="RadioChooseGroup"
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="150ml"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="150ml"
                        control={<Radio />}
                        label={
                          <Typography className="labeltxt">
                            {currentProductItem.productQuantity} <b>${currentProductItem.productPrice}</b>
                          </Typography>
                        }
                      />

                    </RadioGroup>
                  </Grid>
                </Grid>

                <Box className="FooterModal d-flex">
                  <span className="itemTxt">Total Price: <span className="itemPrice">${currentProductItem.productPrice}</span></span>
                  <Button
                    className="button-radius btn ml-auto gradients-bg"
                    variant="contained"
                    onClick={() => { addProductToCart(currentProductItem) }}
                  >
                    <ShoppingCartOutlinedIcon className="svg-icon mr-5"></ShoppingCartOutlinedIcon>
                    Add Item
                  </Button>
                </Box>


              </>
            }
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
