import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { customerActions, authActions, store } from '_store';
import { history, fetchWrapper } from '_helpers';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Footer } from "footer/Footer";
import { Container } from "@mui/system";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LocalPostOfficeOutlinedIcon from "@mui/icons-material/LocalPostOfficeOutlined";

import "./myaccount.scss";
import { Button, List, ListItem } from "@mui/material";
import { NavLink } from "react-router-dom";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import SlideToggle from "react-slide-toggle";
import MenuIcon from "@mui/icons-material/Menu";


const steps = [
  "Order Accepted",
  "Preparing",
  "Ready For Pick Up",
  "Completed Order",
];

export { OrderList };

const columns = [
  {
    id: "order",
    label: "Order #",
    labelicon: <SortOutlinedIcon />,
    minWidth: 50,
  },
  { id: "date", label: "Date", labelicon: <SortOutlinedIcon />, minWidth: 200 },
  {
    id: "ordertotal",
    label: "Order Total",
    labelicon: <SortOutlinedIcon />,
    minWidth: 100,
  },
  {
    id: "status",
    label: "Status",
    labelicon: <SortOutlinedIcon />,
    minWidth: 100,
  },
  {
    id: "action",
    label: "Action",
    labelicon: <SortOutlinedIcon />,
    minWidth: 170,
  },
];

function createData(order, date, ordertotal, status, action) {
  return { order, date, ordertotal, status, action };
}

const rows = [
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
  createData(
    "#3458",
    "15-05-2022 @ 12:30 pm",
    "$ 15",
    "Accepted",
    "View Order | Reorder"
  ),
];



function OrderList() {
  const dispatch = useDispatch();
  const { getOrderList: customerOrderList } = useSelector((x) => x.customer);

  useEffect(() => {
    dispatch(customerActions.getOrderList());
  }, []);

  const [orderList, setOrderList] = React.useState(false);
  useEffect(() => {
    if (typeof customerOrderList != "undefined" && customerOrderList && !customerOrderList.loading) {

      const dataOrder = customerOrderList.map(function (object, i) {
        return createData(
          "#" + object.id,
          object.pickupTime,
          "$ " + object.orderTotal,
          object.orderStatus,
          "View Order | Reorder"
        ) 
      })

      setOrderList(dataOrder) 
    }
  }, [customerOrderList]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenProfile = () => setOpenProfile(true);
  const handleCloseProfile = () => setOpenProfile(false);
  const [toggleEvent, setToggleEvent] = React.useState(0);

  const handleToggleShow = () => {
    setToggleEvent(Date.now());
  };
  const myAccountOrderMenu = (
    <List>
      <ListItem>
        <NavLink to="/orderlist">Orders List</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/account-settings">Account Settings</NavLink>
      </ListItem>
      <ListItem>
        <NavLink to="/support">Support</NavLink>
      </ListItem>
    </List>
  )


  return (
    <Box className="light-blue-bg">
      <Container fixed className="py-60 maxWidthLg">
        <Box className="white-bg border-radius-8 myaccountInfo">
          <Grid container className="d-flex profileBtnRow">
            <Grid item xs={12} sm={8}>
              <h2 className="font-32 pColor pb-20">Hi, Mark Smith</h2>

              <p className="font-small d-flex flex-wrap orderphonemailInfo">
                <span className="pr-25 d-flex pt-5 info">
                  <LocalPhoneOutlinedIcon className="mr-5" /> 510-554-4468
                </span>
                <span className="d-flex pt-5 info">
                  <LocalPostOfficeOutlinedIcon className="mr-5" />
                  <a href="mailto:marksmith@gmail.com">
                  marksmith@gmail.com
                  </a>
                </span>
              </p>

            </Grid>
            <Grid item xs={12} sm={4} className="d-flex pt-xs-20">
              <Button
                className="gradients-bg btn btn-h-40 border-radius-50 mw-150 ml-auto ml-xs-0"
                onClick={handleOpenProfile}>
                Edit Profile
              </Button>
            </Grid>
          </Grid>
          <Grid container className="myAccount-leftRight">
            <Grid item xs={12} sm={3} className="d-flex">
              <Box className="myaccount-left border-radius">


                <Box className="hiddenSM">
                  <Box sx={{ p: 2 }}>
                    <span onClick={handleToggleShow} className="subMenuArrow d-flex">
                      Menu <MenuIcon className="ml-auto" />
                    </span>
                  </Box>
                  <SlideToggle toggleEvent={toggleEvent} collapsed>
                    {({ setCollapsibleElement }) => (
                      <div className="submenuContent w-100" ref={setCollapsibleElement}>
                        {myAccountOrderMenu}
                      </div>
                    )}
                  </SlideToggle>
                </Box>

                <Box className="hiddenXS">
                  {myAccountOrderMenu}
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={9}
              className="flex-wrap myaccountRight">
                
              {/* <Box className="orderStepBar w-100 hiddenXS">

                <Stepper activeStep={1} alternativeLabel className="stepBar">
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <h2 className="font-large hiddenSM mt-30">Status: <span className="ml-auto font-small">Order Accepted</span></h2> */}

              <Box className="customerOrderTablelayout w-100">
                <TableContainer className="customerOrderTable" sx={{ maxHeight: 756 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                            {column.labelicon}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderList && orderList.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((orderList) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={orderList.code}
                            >
                              {columns.map((column) => {
                                const value = orderList[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="tablebottombar"
                  rowsPerPageOptions={[10, 15, 100]}
                  component="div"
                  count={orderList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />

      <Modal
        className="modalPopupTxt"
        open={openProfile}
        onClose={handleCloseProfile}
      >
        <Box className="modalPopupOverflow">
          <Box className="modalPopupTxt-inner">
            <Button className="closepopup" onClick={handleCloseProfile}>
              <CloseIcon />
            </Button>
            <h2 className="font-subheading pColor" id="modal-modal-title">
              Edit Profile
            </h2>

            <FormControl fullWidth className="mt-20">
              <label>Your Name</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="Normal"
              />
            </FormControl>

            <FormControl fullWidth className="mt-20">
              <label>Phone Number</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="510-554-4468"
              />
            </FormControl>

            <FormControl fullWidth className="mt-20">
              <label>E-mail</label>
              <TextField
                className="form-control"
                hiddenLabel
                id="filled-hidden-label-normal"
                placeholder="marksmith@gmail.com"
              />
            </FormControl>

            <Button className="gradients-bg btn btn-h-40 border-radius-50 w-100 mt-15" variant="contained">
              Save
            </Button>
          </Box> 
        </Box>
      </Modal>
    </Box>
  );
}
