import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Sidebar } from 'ShopDashboard/sidebar/Sidebar';
import { Footer } from 'footer/Footer';
import { NavLink } from 'react-router-dom';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import RingVolumeOutlinedIcon from '@mui/icons-material/RingVolumeOutlined';
import helpDemovideo from "images/helpDemovideo.png";
import './help.scss';
import { Button, TextField } from '@mui/material';  

export { Help };

function Help() {


    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box className="dashboardRightcontent enquiryInfoOuter">

                <Box className="helplayout">
                    <Box className="white-bg">
                        <h2 className='font-36 pColor pb-20'>Hi, Mark Smith</h2>
                        <Box className="phonemailInfo">
                            <NavLink className="info"><LocalPhoneOutlinedIcon /> 510-554-4468</NavLink>
                            <NavLink className="info"><MailOutlinedIcon /> marksmith@gmail.com</NavLink></Box>

                        <Box className="borderLine"></Box>
                        <Grid container spacing={4} className='helpcontainer'>
                            <Grid item xs={12} sm={12} lg={7}>
                                <Box><img src={helpDemovideo} className='w-100' /></Box>
                                <Box className="d-flex supportInfo pt-20">
                                    <span><RingVolumeOutlinedIcon /> Toll free <br />
                                        <b><a className="font-subheading pColor" href="tel:0898989800">0898989800</a></b></span>
                                    <span><MailOutlinedIcon /> Email<br />
                                        <b><a className="font-subheading pColor" href="mailto:sale@shopmart.com">sale@shopmart.com</a></b>
                                    </span>
                                </Box>
                            </Grid>

                            <Grid className='typeInfo' item xs={12} sm={12} lg={5}>
                                 <h3 className="font-36 pColor">Type your message</h3>
                                 <Box className="pt-20">
                                 <label className='pb-10 d-block'>Name</label>
                                 <TextField className='w-100 form-control' variant="outlined" />
                                 </Box>
                                 <Box className="pt-20">
                                 <label className='pb-10 d-block'>Message</label>                                  
                                 <TextField className='w-100 form-control' multiline rows={4} placeholder="Type"  />
                                 </Box>

                                 <Box className="pt-20 text-align-right">
                                  <Button className='gradients-bg btn btn-h-40 mw-150' variant="contained" size="large">Send</Button>
                                     
                                 </Box>

                            </Grid>
                        </Grid>

                    </Box>
                </Box>

                <Footer />
            </Box>
        </Box>
    );
}