import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '_helpers';
import { authActions } from '_store';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container'; 
import Grid from '@mui/material/Grid'; 
import Checkbox from '@mui/material/Checkbox';
import { NavLink } from 'react-router-dom';

import './login.scss'; 

import demoimg from 'images/demo-img.png';
import googelicon from 'images/google-icon.png';
import facebookicon from 'images/facebook-icon.png';

export { SignUp };

function SignUp() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    function onSubmit({ username, password }) {
        return dispatch(authActions.signin({ username, password }));
    }

    return ( 

        
        <Container fixed className='login-wrapper'> 

        <Grid container>
           <Grid item xs={12} sm={6}>
              <Box className="card"> 
                  <Box className="login-bg">
                    <h2 className='mainHeading'>Sign Up</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box className="form-group">
                            <label>Email or mobile number</label>
                            <input name="username" placeholder='Email or mobile number' type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                            <Box className="invalid-feedback error">{errors.username?.message}</Box>
                        </Box>
                        <Box className="form-group">
                            <label>Password</label>
                            <input name="password" placeholder='Password' type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                            <div className="invalid-feedback error">{errors.password?.message}</div>
                        </Box>
                          <button disabled={isSubmitting} className="btn primary-btn w-100">
                            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                            Get Started
                        </button>
                        {authError &&
                            <div className="error">{authError.message}</div>
                        }
                    </form>
                    <Box className="text-line w-80 text-center mx-auto my-30"><span>or get started with</span></Box>
                    <Box className="btn-row d-flex">
                    <Button className='btn w-100 white-btn social-login-btn' variant="outlined"><img src={googelicon} className="mr-10" />Google</Button>
                    <Button className='btn w-100 white-btn social-login-btn' variant="outlined">
                        <img src={facebookicon} className="mr-10" /> Facebook</Button>
                    </Box>

                    <Box className="d-flex pt-20 form-group checkbox-txt mt-20"> 
                      <Checkbox /><p>By continuing, you agree to our <b>Term & Conditions</b> and <b>Privacy Policy</b>.</p>
                    </Box>   
                </Box>
                <Box className='text-center pt-30'><p>Already a member?  <b><NavLink to="/signin">Sign In</NavLink></b></p></Box>
            </Box>
    </Grid>
    
    <Grid item xs={12} sm={6} p={6}>
    <img src={demoimg} className="w-80 mx-auto d-block " />
    </Grid>
    </Grid>
  </Container>
    )
}
