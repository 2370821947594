import React,{useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "./css/custom.min.scss";

import { Nav, PrivateRoute, CustomerRoute } from "_components";
import { PublicHeader } from "./_components/Header";
import { Footer } from "footer/Footer";
import { Home } from "customer/pages/home";
import { SignIn } from "customer/pages/login/SignIn";
import { SignUp } from "customer/pages/login/SignUp";
import { MyAccount } from "customer/pages/profile/MyAccount";
import { OrderList } from "customer/pages/profile/OrderList";
import { Support } from "customer/pages/profile/Support";
import { ShopPage } from "customer/pages/shopPage/ShopPage";
import { StorePage } from "customer/pages/storePage/StorePage";
import { CheckOut } from "customer/pages/checkOut/CheckOut";
import { Thanks } from "customer/pages/thanks/Thanks";
import { ShopSignUp } from "ShopDashboard/shopsignup/ShopSignUp";
import { Dashboardadmin } from "ShopDashboard/admin/Dashboardadmin";
import { Order } from "ShopDashboard/order/Order";
// import { NewOrder } from "ShopDashboard/order/NewOrders";
import { Menupage } from "ShopDashboard/menu/Menupage";
import { AddProduct } from "ShopDashboard/menu/AddProduct";
import { MenuCategories } from "ShopDashboard/menu/MenuCategories";
import { Profile } from "ShopDashboard/profile/Profile";
import { EditProfile } from "ShopDashboard/profile/EditProfile";
import { Help } from "ShopDashboard/help/Help";
import { Subscription } from "ShopDashboard/subscription/Subscription";

export { App };

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}


function App() {
  return (
    <div className="app-container">
      <PublicHeader />
      <ScrollToTop />
      <div className="fullwrapper">
        <Routes>
          <Route path="/orders/:type" element={<PrivateRoute><Order /></PrivateRoute>} />
          <Route path="/orders" element={<PrivateRoute><Order /></PrivateRoute>} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboardadmin />
              </PrivateRoute>
            }
          />
          <Route path="/home" element={<Home />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/shop/:cat" element={<ShopPage />} />
          <Route path="/store/:shopid" element={<StorePage />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/myaccount" element={<CustomerRoute><MyAccount /></CustomerRoute>} />
          <Route path="/orderlist" element={<CustomerRoute><OrderList /></CustomerRoute>} />
          <Route path="/support" element={<CustomerRoute><Support /></CustomerRoute>} />
          <Route path="/shopsignup" element={<ShopSignUp />} />
          <Route path="/menu" element={<Menupage />} />
          <Route path="/menucategories" element={<MenuCategories />} />
          <Route path="/addproduct" element={<AddProduct />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit-profile" element={<EditProfile />} />
          <Route path="/help" element={<Help />} />
          <Route path="/subscription" element={<Subscription />} />
        </Routes>
      </div>
    </div>
  );
}
