import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { commonActions, authActions, store } from '_store';
import { history } from '_helpers'; 
import { Box } from "@mui/system";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { NavLink, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import demoVector from "images/demoVector.png";
import EssentialsImg from "images/essentialsImg.png";
import StoreImg from "images/storeImg.png";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { BrowserRouter as Router,  useParams } from 'react-router-dom'; 
import Pagination from '@mui/material/Pagination';
import "./shop.scss";

export { ShopPage };

function ShopPage() {
  const { cat } = useParams();   
  const dispatch = useDispatch();
  const { topRatedShops: TopRatedShopsList } = useSelector((x) => x.common);
  const [shopList, setShopList] = useState([]);
   const [category, setCategory] = useState([]);
   const { parentCategoryDetail: catInfo } = useSelector((x) => x.common);
   useEffect(() => {
    if (cat && cat !="") {  
      const catId = { catId: cat };
      dispatch(commonActions.parentCategoryDetail(catId)); 
    }  
  }, [cat]);

  useEffect(() => {
   
    if(catInfo && catInfo.data  ){ 
      setCategory(catInfo.data[0]);
    }
  }, [catInfo]);


  // useEffect(() => {
  //   if (location && location.state && location.state.category) {   
  //     setCategory(location.state.category);
  //   } else { 
  //     //history.navigate('/home');
  //   } 
  // }, [location.state]);
  useEffect(() => {
      window.scrollTo(0, 0) 
      dispatch(commonActions.topRatedShops());  
  }, []);

  useEffect(() => { 
    if (typeof TopRatedShopsList != "undefined" && TopRatedShopsList && !TopRatedShopsList.loading) {
   setShopList(TopRatedShopsList)
    }
  }, [TopRatedShopsList]);

  return (
    <Box>
      <Box className="py-30 InnerPageHeader">
        <Box className="container">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" to="/home">
              Home
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to=""
              aria-current="page"
            >
              {category.categoryName}
            </NavLink>
          </Breadcrumbs>

          <Box className="d-flex pt-20">
            <Box className="shopImg">
            <img src={EssentialsImg} />
            </Box>
            <Box className="pl-20">
              <h2 className="font-subheading">{category.categoryName}</h2>
              <p className="pt-10 wColor">4 Stores</p>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="container py-60">
        <Grid
          container
          spacing={[3, 3]}
          columnSpacing={{ xs: 0, sm: 3, md: 3 }}
          className="shopListInfo"
        >
          {shopList && Object.keys(shopList).length > 0 &&
            shopList.map(function (object, i) {
             return <Grid item xs={12} sm={4} xl={3} className=""> 
             
                <NavLink to={`/store/${object.id}`}  state={{ shop: object, category : category }} className="d-block">
                <Box className="lightGreyBG border-radius-8 d-flex p-20"> 
                  <img src={StoreImg} className="storeImg" />
                <Box className="pl-20">
                  <h3 className="mainHeading font-subheading">{object.title}</h3>
                  <p className="pt-5">{object.address}</p>
                  {/* <p className="pt-10 d-flex">
                    <span className="d-flex SvgIcon">
                      <FmdGoodOutlinedIcon /> 3.0 km
                    </span>
                    <span className="d-flex pl-15 SvgIcon">
                      <AccessTimeOutlinedIcon /> 25 mins
                    </span>
                  </p> */}
                </Box> 
                </Box>
                </NavLink>
              </Grid>
            })}  
          {TopRatedShopsList && TopRatedShopsList.loading &&
            <Grid item xs={12} sm={6} className="d-flex">
              Loading....
            </Grid>
          } 
        </Grid>

        <Pagination className="customPagination" count={8} variant="outlined" shape="rounded" />

      </Box>
    </Box>
  );
} 