import * as React from 'react'; 
import { styled, alpha } from '@mui/material/styles'; 
import Box from '@mui/material/Box';     
import { NavLink } from 'react-router-dom';
import Container from '@mui/material/Container'; 
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem'; 

import './footer.scss';  

import footerlogo from 'images/shopmart-footer-logo.png';
const Shopmartlinks = [
    {  
        to: '/about',
        lable: 'About'
    },
    {
        to: '/contact',
        lable: 'Contact'
    },
    { 
        to: '/terms-conditions',
        lable: 'Terms & Conditions'
    },
    { 
        to: '/privacy-policy',
        lable: 'Privacy Policy'
    },
    { 
        to: '/shopmart-for-business',
        lable: 'Shopmart for business'
    },

]
 
  
export function Footer() {    
  return (
    <footer className='footer-bg'> 
    <Container fixed className="container"> 
      

        <Grid container className="footer-wrapper d-flex flex-wrap align-items pb-20" spacing={3} pt={5}>
            <Grid item xs={12} sm={6} lg={8} className="footerAboutInfo">
                  <Box className="footer-txt">
            <h2 className='white-color'>You can’t stop time, but you can save it!</h2>
              <p className='pt-10'>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
an unknown printer took a galley of type and scrambled it to make a type ...</p>
              </Box>
              </Grid>

              <Grid item xs={12} sm={6} lg={4} className="ml-auto d-flex">
              <NavLink to="/shopsignup" className="btn white-btn footerShopBtn">Business with shopmart</NavLink>
              </Grid>
              </Grid>
        
            <Grid container className="footer-wrapper" spacing={3} pt={5}>
            <Grid item xs={12} sm={6} lg={3} className="login-bg">
             <NavLink to="/"><img className="d-block" src={footerlogo} /></NavLink>
            </Grid>  
            <Grid item xs={12} sm={6} lg={3}> 
              <h2>Shopmart</h2>
               <List className='footelinks'>
               {Shopmartlinks.map((link,i) => (
                 <ListItem key={i}>   
                 <NavLink to={link.to}> {link.lable} </NavLink> 
                </ListItem>   
                 ))} 
              </List>
            </Grid> 
            <Grid item xs={12} sm={6} lg={3}> 
            <h2>Serviceable Cities</h2>
            <List className='footelinks'>
               {Shopmartlinks.map((link,i) => (
                 <ListItem key={i}>  
                 <NavLink to={link.to}> {link.lable} </NavLink> 
                </ListItem>   
                 ))} 
              </List>
            </Grid> 
            <Grid item xs={12} sm={6} lg={3}> 
            <h2>Get In Touch</h2>
            <List className='footelinks'>
            {Shopmartlinks.map((link,i) => (
                 <ListItem key={i}>  
                 <NavLink to={link.to}> {link.lable} </NavLink> 
                </ListItem>   
                 ))} 
              </List>
            </Grid> 
           </Grid> 
     </Container>
     </footer>
  )
}