import * as React from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import ThanksGirlimg from "images/thanks-girlimg.jpg";
import ThankText from "images/thank-txt.png";
import mainthankLogo from "images/thankLogo.svg";

import "./thanks.scss";
import { Grid } from "@mui/material";

export { Thanks };

function Thanks() {
  return (
    <Box className="Thanksbg text-center">
      <Box className="container pb-40">
        <Box className="goHomeBtn">
          <NavLink
            to="/"
          >
            <img src={mainthankLogo} />
          </NavLink>
        </Box>

        <Grid container spacing={[3, 4]} columnSpacing={{ xs: 0, sm: 3, md: 4 }} className="mt-0">
          <Grid item xs={6} sm={6} lg={6} className="ThanksLeftCol">
            <Box className="w-100 mx-auto">
              <img src={ThankText} className="thankTxt" />
            </Box>
            <p className="pt-30 bColor font-large">Your order has been placed successfully</p>

          </Grid>
          <Grid item xs={6} sm={6} lg={6} className="ThanksRightCol">
            <img src={ThanksGirlimg} className="w-100" />
          </Grid>
        </Grid>

      </Box>
    </Box>
  );
}
